import React, { Component } from "react";

import classNames from 'classnames'
import './Loader.scss';

export default class Loader extends React.Component {


    static defaultProps = {
        zIndex: 55,
        visible: false,
        opacity: false,
        noOpacity: false,
        delay: true,
        fixed: false,
    }


    render() {

        var { zIndex, visible, noOpacity, delay, className = '', fixed, size } = this.props;

        var styles = {
            display: visible ? "block" : "none",
            zIndex: zIndex,
        };



        var classes = className + ' ' + classNames({
            'Loader__showbox': true,
            fixed: fixed,

        });

        classes += ` size-${size}`;


        return (
            <div
                className={classes}
                style={styles}
            >
                <div className="Loader__loader">
                    <svg className="Loader__circle" viewBox="25 25 50 50">
                        <circle
                            className="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                        />
                    </svg>
                </div>
            </div>
        );
    }
}

