import { Info, InfoFill } from "./Info";

import './InfoBar.scss'

export default function InfoBar({ title, children }) {
    return <div className="InfoBar">
        <div className="InfoBar__icon">
            <InfoFill />
        </div>
        <div className="InfoBar__content">
            <div className="InfoBar__title" >{title}</div>
            {children}
        </div>
    </div>
}