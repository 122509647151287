import React, { Children, Component } from 'react';

import './DropdownPopover.scss';

class DropdownPopover extends Component {
    state = {
        isOpen: false
    }
    node;
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    handleClickOutside(e) {
        if (this.node && this.state.isOpen && !this.node.contains(e.target)) {
            this.setState({
                isOpen: false
            });
        }
    }



    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    render() {

        const { items = [], children, position = 'right', width = 'auto', onChange, value = null } = this.props;

        var styles = {
            display: this.state.isOpen ? "block" : "none",
            width: width
        };

        return (

            <div ref={el => this.node = el} className={`DropdownPopover ${this.state.isOpen ? 'active' : ''} amount-${items.length} position-${position}`}>
                <button onClick={this.toggle} className="DropdownPopover__button">{children}</button>
                <ul style={styles} className="DropdownPopover__list">
                    {
                        items.map((link, i) => {
                            const classes = value === link['id'] ? ' checked ' : ''
                            return <li key={i} className={classes}>
                                <div onClick={e => {
                                    onChange(link)
                                }} >
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle className='outer' cx="11" cy="11" r="10.5" stroke="#4BB8E8" />
                                        <circle className='inner' cx="11" cy="11" r="6" fill="#4BB8E8" />
                                    </svg>

                                    {link['name']}
                                </div>
                            </li>
                        })
                    }

                </ul>
            </div>
        );
    }
}

export default DropdownPopover;
