import en from "../translations/en";
import dk from "../translations/dk";

import { store } from "../stores/Store";
import { observer } from "mobx-react-lite";

const langs = {en, dk};

export const __ = (str) => {
    if(langs[store.currentLangCode] && langs[store.currentLangCode][str]){
        return langs[store.currentLangCode][str];
    }
    if(en[str]){
        return en[str];
    }
    return str;
}