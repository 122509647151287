import { useEffect } from 'react';
import './Modal.scss';

import { hideAll } from 'tippy.js';

function Modal({ children, footer = null, isOpen = false, onClose, className = '' }) {
    var classes = `${className} `;


    useEffect(() => {

        if (isOpen) {
            window.document.documentElement.classList.add('has-modal');
            hideAll();
        } else {
            window.document.documentElement.classList.remove('has-modal');
        }


    }, [isOpen])

    if (isOpen) {
        classes += ' is-open ';
    }
    return (<div className={`Modal__overlay ${classes}`}>
        <div className="Modal">
            <button type='button' onClick={onClose} className="Modal__close" aria-label='Close'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.541 16.5425L8.99852 9.00006M8.99852 9.00006L1.45605 1.45759M8.99852 9.00006L16.541 1.45759M8.99852 9.00006L1.45605 16.5425" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </button>
            <div className="Modal__content">
                {children}
            </div>
            <div className="Modal__footer">
                {footer}
            </div>
        </div>
    </ div>
    )
}

export {
    Modal
}