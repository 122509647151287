import Tooltip from "./Tooltip.jsx"




export function Info({ title, onClick = null }) {
    const ico = <svg onClick={onClick} className="Info" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 4.42857V5.57143M9 7.85714V13V7.85714Z" stroke="#4BB8E8" strokeWidth="1.15" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#4BB8E8" strokeWidth="1.15" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    if (title) {
        return <Tooltip title={title}>
            {ico}
        </Tooltip>
    }
    return ico;

}

export function InfoFill() {
    return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1.375C13.5527 1.375 16.0009 2.38906 17.8059 4.1941C19.6109 5.99913 20.625 8.44729 20.625 11C20.625 13.5527 19.6109 16.0009 17.8059 17.8059C16.0009 19.6109 13.5527 20.625 11 20.625C8.44729 20.625 5.99913 19.6109 4.1941 17.8059C2.38906 16.0009 1.375 13.5527 1.375 11C1.375 8.44729 2.38906 5.99913 4.1941 4.1941C5.99913 2.38906 8.44729 1.375 11 1.375ZM11 5.5C10.8254 5.49985 10.6527 5.53611 10.493 5.60647C10.3332 5.67682 10.1899 5.77973 10.0721 5.90861C9.95438 6.03748 9.86481 6.1895 9.80912 6.35495C9.75344 6.52041 9.73288 6.69565 9.74875 6.8695L10.2506 12.3777C10.2701 12.5629 10.3574 12.7344 10.4958 12.859C10.6342 12.9836 10.8138 13.0525 11 13.0525C11.1862 13.0525 11.3658 12.9836 11.5042 12.859C11.6426 12.7344 11.7299 12.5629 11.7494 12.3777L12.2499 6.8695C12.2657 6.69577 12.2452 6.52064 12.1896 6.35528C12.134 6.18992 12.0446 6.03797 11.927 5.90911C11.8094 5.78026 11.6662 5.67732 11.5066 5.60688C11.347 5.53644 11.1745 5.50004 11 5.5ZM11 16.5C11.2917 16.5 11.5715 16.3841 11.7778 16.1778C11.9841 15.9715 12.1 15.6917 12.1 15.4C12.1 15.1083 11.9841 14.8285 11.7778 14.6222C11.5715 14.4159 11.2917 14.3 11 14.3C10.7083 14.3 10.4285 14.4159 10.2222 14.6222C10.0159 14.8285 9.9 15.1083 9.9 15.4C9.9 15.6917 10.0159 15.9715 10.2222 16.1778C10.4285 16.3841 10.7083 16.5 11 16.5Z" fill="#4BB8E8" />
    </svg>


}