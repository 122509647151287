import './ProgressBar.scss'


const ProgressBar = ({ progress }) => {
    return <div className="ProgressBar">
        <div style={{
            width: `${progress * 100}%`
        }} className="ProgressBar__progress"></div>
    </div>
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar