import { observer } from 'mobx-react-lite'
import { PUBLIC_URL } from '../App';
import { store } from '../stores/Store';
import { formatCurrency } from '../utils/Currency';
import { simpleRouter } from '../utils/Router';
import { __ } from '../utils/translation';
import { Button } from './Button'
// import './SummaryList.scss';

const SummaryList = observer(() => {
    const project = store.project;
    const materialName = project.selectedMaterial ? __(`mat${project.selectedMaterial.id}_title`) : '';
    const finishName = project.selectedFinish ? __(`finish${project.selectedFinish.id}_title`) : '';
    const addonsList = project.selectedAddons.length > 0 ? project.selectedAddons.map(a => __(`addon${a.id}_title`)).join(', ') : '';
    const designs = project.designs.filter(d => d.quantity).map((d, i) => <div key={i}>{i + 1}-{d.quantity}{__('pieces')}</div>)
    return (
        <div className="SummaryList__wrapper">
            <div className="SummaryList__shadow"></div>
            <div className="bg-white">
                <div className="default-wrapper ">

                    <div className="d-flex SummaryList">
                        <div className="SummaryList__item SummaryList__item-name">
                            <div className="SummaryList__item-label">{__(store.project.config.type)}</div>
                            <div className="SummaryList__item-value">{project.config?.size}mm</div>
                            <div className="SummaryList__item-action">
                                <a href={store.webUrl} className="link fs-12">{__('back_to_type_selection')}</a>
                            </div>

                        </div>
                        <div className="SummaryList__item SummaryList__item-title">
                            <div className="SummaryList__item-label">{__('material_title')}</div>
                            <div className="SummaryList__item-value">{materialName}</div>
                            <div className="SummaryList__item-action">

                            </div>

                        </div>
                        <div className="SummaryList__item">
                            <div className="SummaryList__item-label">{__('finish_title')}</div>
                            <div className="SummaryList__item-value">{finishName}</div>
                            <div className="SummaryList__item-action">

                            </div>

                        </div>
                        <div className="SummaryList__item SummaryList__item-addons">
                            <div className="SummaryList__item-label">{__('addons_title')}</div>
                            <div className="SummaryList__item-value">{addonsList}</div>
                            <div className="SummaryList__item-action">

                            </div>

                        </div>
                        <div className="SummaryList__item">
                            <div className="SummaryList__item-label">{__('designs_title')}</div>
                            <div className="SummaryList__item-value">
                                {designs}
                            </div>
                            <div className="SummaryList__item-action">
                            </div>
                        </div>
                        <div className="SummaryList__item">
                            <div className="SummaryList__item-label">{__('total_quantity')}</div>
                            <div className="SummaryList__item-value">{project.totalQuantity > 0 ? `${project.totalQuantity}${__('pieces')}` : ''}</div>
                            <div className="SummaryList__item-action">
                            </div>
                        </div>
                        <div className="SummaryList__item">
                            <div className="SummaryList__item-label">{__('price')}</div>
                            <div className="SummaryList__item-value">{project.totalPrice > 0 ? `${formatCurrency(project.totalPrice)}` : ''}</div>
                            <div className="SummaryList__item-action">
                            </div>
                        </div>
                        <div className="SummaryList__item actions">
                            <Button
                                onClick={async e => {

                                    try {
                                        const response = await project.saveDraft();

                                    } catch (err) {
                                        console.error(err)
                                    }
                                }}
                                disabled={!store.firstStepValid}
                                className='btn primary hollow'>{__('btn_save_draft')}</Button>
                            <Button
                                onClick={async e => {

                                    try {
                                        const response = await project.addToCart();

                                    } catch (err) {
                                        console.error(err)
                                    }
                                }}
                                disabled={!store.designStepValid} className='btn primary'>{project.is_added_to_cart ? __('btn_update_cart') : __('btn_add_to_cart')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export {
    SummaryList
}