import { observer } from "mobx-react-lite";
import { publicDir } from "../utils/url";

import './SmallCard.scss'

import { Checked } from '../icons/Checked'

const SmallCard = observer(({ checked = false, onClick, title, image, disabled = false }) => {

    var classes = '';
    if (checked) {
        classes += ' checked ';
    }
    if (disabled) {
        classes += ' disabled ';
    }

    return <button disabled={disabled} onClick={onClick} className={`SmallCard ${classes}`}>
        <div className="SmallCard__inner">
            <img src={publicDir(image)} alt="" />
            {checked && <div className="checked-icon">
                <Checked />
            </div>}
        </div>
        <div className="SmallCard__desc">
            <p>{title}</p>
        </div>
    </button>
})
SmallCard.displayName = 'SmallCard'
export { SmallCard }