import Collapse from "./Collapse";

import './AccordionItem.scss';
import { useEffect, useState } from "react";
import PlusIcon from "../icons/PlusIcon";

export function AccordionItem({ children, isOpen = true, title, onClick, controlled = true, interactive = true }) {
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        if (isOpen && !open) {
            setOpen(isOpen);
        }
    }, [isOpen])

    if (!onClick) {
        onClick = () => {
            if (interactive || !open) {
                setOpen(!open);
            }
        }
    }
    return <div className={`AccordionItem ${open ? 'is-open' : ''}`}>
        <div onClick={onClick} className="AccordionItem__title">
            {title}
            <div className="AccordionItem__plus-icon">
                <PlusIcon />
            </div>
        </div>
        <Collapse isOpen={open}>

            {children}
        </Collapse>
    </div>
}