// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import './SwiperList.scss';
import { observer } from 'mobx-react-lite';
import { store } from '../stores/Store';
import { SmallCard } from './SmallCard';

const SwiperList = observer(({ items = [], renderItem, ...rest }) => {

    var props = {
        spaceBetween: 0,
        slidesPerView: 5,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2.8,
                spaceBetween: 0
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2.8,
                spaceBetween: 0
            },
            // when window width is >= 640px
            900: {
                slidesPerView: 5,
                spaceBetween: 0
            }
        },
        ...rest
    }
    return (
        <div className="SwiperList">
            {/* {items.map((item, i) => {
                return <SmallCard
                    title={item.title}
                    image={item.image}
                    checked={store.selectedMaterialId === item.id}
                    onClick={e => {
                        store.selectMaterial(item.id)
                    }} />
            })} */}

            <Swiper
                {...props}
                onSlideChange={() => { }}
                onSwiper={(swiper) => { }}
            >
                {
                    items.map((item, i) => {
                        return <SwiperSlide key={i}>{
                            renderItem(item, i)
                        }</SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );

})
SwiperList.displayName = 'SwiperList'



export {
    SwiperList
}