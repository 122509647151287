import logo from './logo.svg';
import './App.scss';

// import {observer} from 'mobx-react';
import React from 'react';
import { observable, makeAutoObservable } from 'mobx'
import { observer, } from "mobx-react-lite"
import { Button } from './components/Button';
import { Info } from './components/Info';
import { Accordion } from './components/Accordion';
import { AccordionItem } from './components/AccordionItem';
import { SmallCard } from './components/SmallCard';
import { SummaryList } from './components/SummaryList';
import { SwiperList } from './components/SwiperList';
import { BubbleButton } from './components/BubbleButton';
import { Step, store } from './stores/Store';
import FeaturesStep from './pages/FeaturesStep';
import DesignsStep from './pages/DesignsStep';
import { useEffect } from 'react';
import { useState } from 'react';
import SketchfabRender from './components/SketchfabRender';
import Loader from './components/Loader';
import { __ } from './utils/translation';
import { Arrow } from './icons/Arrow';
import { Modal } from './components/Modal';
import ReportModal from './components/ReportModal.jsx';
import Switch from './components/Switch.jsx';
import Preloader from './components/Preloader';
import OverlayCover from './components/OverlayCover';
import DropdownPopover from './components/DropdownPopover';
import { Link } from './components/Link';
import YouTube from 'react-youtube';
import { VideoModal } from './components/VideoModal.jsx';
import {isMobileDevice, isTablet} from './utils/Device';

export const PUBLIC_URL = process.env.PUBLIC_URL;

const App = observer(() => {

    const [watchVideoModalVisible, setWatchVideoModalVisible] = useState(false);
    const [videoModalVisible, setVideoModalVisible] = useState(false);

    const height = !isMobileDevice() ? window.innerHeight * 0.8 : isTablet()  ? window.innerHeight * 0.6 : window.innerHeight * 0.3;
    const opts = { height: height, width: '90%' };

    useEffect(() => {
        const isFirstVisit = localStorage.getItem('isFirstVisit');

        if (!isFirstVisit) {
            localStorage.setItem('isFirstVisit', 'true');
            setWatchVideoModalVisible(true);
        }
    }, []);

    // useEffect(() => {
    //     store.loadConfiguration();
    // }, [])

    return (
        <div className={`App ${store.pendingRequest ? 'App--preloading' : ''}`}>
            <Preloader visible={store.pendingRequest} />
            <header className="AppHeader">
                <div className="default-wrapper">
                    <div className="AppHeader__inner">
                        <div className="AppHeader__logo">
                            {isMobileDevice() ? (
                                <img src={PUBLIC_URL + '/assets/logo-mobile.svg'} alt="Lprint" />
                             ) : (
                                <img src={PUBLIC_URL + '/assets/logo.svg'} alt="Lprint" />
                                 )}
                            | {__('builder')}
                        </div>
                        {/* <nav className="PrimaryMenu">
                            <ul>
                                <li><a href="#">{__('menu_pouch')}</a></li>
                                <li><a href="#">{__('menu_products')}</a></li>
                                <li><a href="#">{__('menu_markets')}</a></li>
                                <li><a href="#">{__('menu_design')}</a></li>
                                <li><a href="#">{__('menu_sustainability')}</a></li>
                                <li><a href="#">{__('menu_resources')}</a></li>
                                <li><a href="#">{__('menu_about')}</a></li>
                            </ul>
                        </nav> */}
                        <div className="AppHeader__buttons">
                            <Button
                                onClick={async e => {
                                    setVideoModalVisible(true);
                                }} 
                                className="btn primary watch-video-btn header-mobile-btn">
                                {isMobileDevice() ? ( __('btn_watch_tutorial_mobile')) : ( __('btn_watch_tutorial'))}
                            </Button>
                            <Link href={store.backUrl} className="btn primary hollow header-mobile-btn">{__('btn_back')}</Link>
                            {/* <button className="btn-icon">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0001 19.381C15.6288 19.381 19.381 15.6288 19.381 11.0001C19.381 6.37142 15.6288 2.61914 11.0001 2.61914C6.37142 2.61914 2.61914 6.37142 2.61914 11.0001C2.61914 15.6288 6.37142 19.381 11.0001 19.381Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.38105 9.42867C8.95964 9.42867 9.42867 8.95964 9.42867 8.38105C9.42867 7.80247 8.95964 7.33344 8.38105 7.33344C7.80247 7.33344 7.33344 7.80247 7.33344 8.38105C7.33344 8.95964 7.80247 9.42867 8.38105 9.42867Z" fill="black" />
                                    <path d="M13.6192 9.42867C14.1977 9.42867 14.6668 8.95964 14.6668 8.38105C14.6668 7.80247 14.1977 7.33344 13.6192 7.33344C13.0406 7.33344 12.5715 7.80247 12.5715 8.38105C12.5715 8.95964 13.0406 9.42867 13.6192 9.42867Z" fill="black" />
                                    <path d="M7.85724 12.0477C8.48895 13.4442 9.53657 14.1429 11.0001 14.1429C12.4636 14.1429 13.5112 13.4442 14.143 12.0477" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </button>
                            <button className="btn-icon cart">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.66669 6.32383H19.1906L17.3478 12.7725C17.209 13.2579 16.9158 13.6849 16.5127 13.9889C16.1096 14.2929 15.6184 14.4573 15.1135 14.4572H7.32864C6.75636 14.4576 6.20407 14.2467 5.77762 13.8651C5.35117 13.4834 5.08054 12.9578 5.01759 12.389L4.08573 4H1.7619" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.9898 18.5239C7.63151 18.5239 8.15171 18.0037 8.15171 17.362C8.15171 16.7203 7.63151 16.2001 6.9898 16.2001C6.34809 16.2001 5.82788 16.7203 5.82788 17.362C5.82788 18.0037 6.34809 18.5239 6.9898 18.5239Z" fill="black" />
                                    <path d="M16.2848 18.5238C16.9265 18.5238 17.4467 18.0036 17.4467 17.3619C17.4467 16.7202 16.9265 16.2 16.2848 16.2C15.6431 16.2 15.1229 16.7202 15.1229 17.3619C15.1229 18.0036 15.6431 18.5238 16.2848 18.5238Z" fill="black" />
                                </svg>

                            </button> */}
                            {/* <DropdownPopover items={store.languages} value={store.currentLangCode} onChange={lang => {
                                store.switchLang(lang.id);
                            }}>
                                {store.currentLangName} <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.19052 0.904755L5.00005 5.09523L0.80957 0.904755" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </DropdownPopover> */}

                        </div>
                    </div>
                </div>
            </header>
            <div className="TabNav">
                <div className="default-wrapper">
                    <button className={`TabNav__tab ${store.step === Step.FEATURES ? 'active' : ''}`} onClick={e => {
                        store.switchStep(Step.FEATURES)
                    }}>
                        <span className="TabNav__tab-inner">
                            1. {__('tab_features')}
                        </span>
                    </button>
                    <button className={`TabNav__tab ${store.step === Step.DESIGN ? 'active' : ''}`} onClick={e => {
                        store.switchStep(Step.DESIGN)
                    }}>
                        <span className="TabNav__tab-inner">
                            2. {__('tab_design')}
                        </span>
                    </button>
                </div>
            </div>
            <div className="main-content">
                <div className="default-wrapper">

                    {!store.error && <div className={`row step-${store.step}`}>
                        <div className="col-form col col-12 col-xl-6 relative">

                            {store.step === Step.FEATURES && <FeaturesStep />}
                            {store.step === Step.DESIGN && <DesignsStep />}


                            <OverlayCover visible={store.project.currentDesign?.uploading} />

                        </div>
                        <div className="col-render col col-12 col-xl-6">
                            <div className="Render  ">
                                <Preloader visible={store.project.currentDesign?.uploading} />
                                <SketchfabRender>
                                    <Button
                                        className='btn primary btn-view-ar' onClick={e => {
                                            store.onCreateAr(e);
                                        }}>{__('btn_view_ar')}</Button>
                                    <button type='button' style={{
                                        display: store.project.designs.length > 1 ? 'block' : 'none'
                                    }} className='btn-arrow btn-prev' onClick={e => {
                                        store.project.prevDesign();
                                    }}><Arrow /></button>
                                    <button type='button' style={{
                                        display: store.project.designs.length > 1 ? 'block' : 'none'
                                    }} className='btn-arrow btn-next' onClick={e => {
                                        store.project.nextDesign();
                                    }}><Arrow /></button>

                                    {/* <Loader fixed={false} zIndex={10} className='Loader__showbox--big' visible={store.project.currentDesign?.uploading} /> */}
                                    {<Switch
                                        labelLeft={__('neutral_label')}
                                        labelRight={__('bright_label')}
                                        name='bg' checked={store.lightBgEnabled} onChange={(checked) => {
                                            store.updateLightBg(checked);
                                        }} />}
                                </SketchfabRender>

                            </div>
                        </div>
                    </div>
                    }

                    {store.error && <div className='text-center'>
                        <h2 className='mt-5'>
                            {store.error}

                        </h2>
                    </div>}
                </div>
            </div>
            <SummaryList />

            <Modal
                className="ArModal"
                isOpen={watchVideoModalVisible}
                onClose={e => {
                    setWatchVideoModalVisible(false);
                }}
                footer={
                <div className='text-center'>
                    <Button
                        style={{ marginBottom: '2rem' }}
                        className='btn btn-lg primary' 
                        onClick={e => {
                            setWatchVideoModalVisible(false);
                            setVideoModalVisible(true);
                        }}>{__('yes_i_do')}
                    </Button>
                </div>
                }
            >
                <div dangerouslySetInnerHTML={{ __html: __('watch_video_description') }}></div>
            </Modal>
            
            <VideoModal
                isOpen={videoModalVisible}
                onClose={e => {
                    setVideoModalVisible(false);
                }}
            >
                {videoModalVisible ? <YouTube videoId="0NB28nOsdJU" opts={opts} /> : <div></div>}
            </VideoModal> 
            
            <Modal
                className=""
                onClose={e => {
                    store.hideMaterialModal();
                }}
                footer={<div className='text-center'>
                    <Button
                        className='btn btn-lg primary' onClick={e => {
                            store.hideMaterialModal();
                        }}>{__('understand')}</Button>
                </div>}
            >

                <div dangerouslySetInnerHTML={{ __html: __('material_description') }}></div>
            </Modal>

            <Modal
                className=""
                isOpen={store.isAddonModalVisible}
                onClose={e => {
                    store.hideAddonModal();
                }}
                footer={<div className='text-center'>
                    <Button
                        className='btn primary' onClick={e => {
                            store.hideAddonModal();
                        }}>{__('understand')}</Button>
                </div>}
            >

                <div dangerouslySetInnerHTML={{ __html: __('addon_description') }}></div>
            </Modal>

            <Modal
                className="ArModal"
                isOpen={store.arModalVisible && (!store.ar.response?.url)}
                onClose={e => {
                    store.hideArModal();
                }}
            >
                <p style={{ marginTop: '2em' }}>
                    {__('ar_loading')}
                </p>
                {store.arLoading && <Loader className='static' visible={true} />}

                {store.ar.response?.error}

            </Modal>


            <div className="ArModalQR" onClick={e => {
                store.hideArModal();
            }} style={{
                display: store.arModalVisible && store.ar.arModelUrl ? 'block' : 'none'
            }}>
                <div className="popup" style={{
                    display: store.arModalVisible && store.ar.arModelUrl ? 'block' : 'none'
                }}>

                    <div className="phone" onClick={e => e.stopPropagation()}>
                        <h1>{__('ar_header')}</h1>
                        <p>
                            {__('ar_text')}
                        </p>
                        <canvas
                            style={{
                                display: store.ar.arModelUrl ? 'block' : 'none'
                            }} rel={store.ar.arModelUrl} width={180} height={180} id="QrModalCanvas"></canvas>

                        <button type='button' onClick={e => {
                            store.hideArModal();
                        }}
                            className="btn primary hollow" id="close_popup">
                            {__('ar_close')}
                        </button>
                    </div>
                </div>
            </div>


            <ReportModal design={store.project.currentDesign} />
        </div>
    );
})
App.displayName = 'App'


export default App;
