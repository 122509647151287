import { action, computed, makeAutoObservable, observable } from "mobx";
import Report from "../stores/Report";
import { BASE_URL, deleteDesignFile, loadDesign, saveDesign, uploadDesignFile } from "../utils/api";
import { delay } from "../utils/Events";
import { uid } from "../utils/Strings";
import ProjectClass from "./ProjectClass";

export class EnfocusStatus{
    static ENFOCUS_WAITING_UPLOAD = 'waiting-upload';
    static ENFOCUS_UPLOADED_TO_FTP = 'uploaded-to-ftp';
    static ENFOCUS_RESPONSE_RECEIVED = 'response-received';
    static ENFOCUS_RESPONSE_SUCCESS = 'response-success';
    static ENFOCUS_RESPONSE_ERROR = 'response-error';
}
export class Design {
    @observable project:ProjectClass;
    @observable report:Report;
    @observable id;
    @observable file: File;
    @observable failedUpload = false;
    @observable name = '';
    @observable size = '';
    @observable file_path = '';
    @computed get fileHash(){
        return String(this.file_path).replace(/\//,'').replace('.pdf','');
    }
    @observable quantity = '';
    @observable removing = false;
    @observable uploading = false;
    @observable enfocus_status = false;
    @observable progress = 1;

    @computed get totalPrice(){
        return  this.totalAddons;
    }
    @computed get totalBags(){
        // const option = this.options.find(o=> o.value === this.quantity);
        // if(option){
        //     return option.price;
        // }
        // return 0;

        
        if(this.project.totalQuantity > 0 && this.options.length){
            var option = this.options.find(o => o.value >= this.project.totalQuantity );
            if(option){
                return option.price;
            }
            return this.options[this.options.length-1].price;
        }
        return 0
    }
    @computed get totalAddons(){
        return this.addons.reduce((sum, a)=> sum + a.totalPrice, 0);
    }
    @computed get addons(){
        const qty = this.quantity;
        if(qty > 0){
            return this.project.selectedAddons.map(a=>{
                return Object.assign({},a,{
                    totalPrice: qty * a.price
                });
            })
        }
        return [];
    }
    // @observable templateUrl = 'https://localhost/create-react-app-mobx/api/design/1/texture';
    // @observable templateTransparentUrl = 'https://localhost/create-react-app-mobx/api/design/1/texture-transparent';
    // @observable templateMetallictUrl = 'https://localhost/create-react-app-mobx/api/design/1/texture-metallic';
    // @observable templateComposableUrl = 'https://localhost/create-react-app-mobx/api/design/1/texture-composable';

    @computed get templateUrl(){
        
        const h = this.fileHash;
        
        if(this.id && this.file_path){
            return `${BASE_URL}/design/${this.id}/texture/${h}`;
        }
        return `${BASE_URL}/design/0/texture/${this.project.config.size}`;
        return null;
    }
    @computed get templateUrlFull(){
        
        const h = this.fileHash;
        
        if(this.id && this.file_path){
            return `${BASE_URL}/design/${this.id}/texture-full/${h}`;
        }
        return `${BASE_URL}/design/0/texture-full/${this.project.config.size}`;
        return null;
    }
    @computed get templateTransparentUrl(){
        
        const h = this.fileHash;
        
        if(this.id && this.file_path){
            return `${BASE_URL}/design/${this.id}/texture-transparent/${h}`;
        }
        return `${BASE_URL}/design/0/texture-transparent/${this.project.config.size}`;
        return null;
    }
    @computed get templateMetallictUrl(){
        
        const h = this.fileHash;
        
        if(this.id && this.file_path){
            return `${BASE_URL}/design/${this.id}/texture-metallic/${h}`;
        }
        return `${BASE_URL}/design/0/texture-metallic/${this.project.config.size}`;
        return null;
    }
    @computed get templateComposableUrl(){
        
        const h = this.fileHash;
        
        if(this.id && this.file_path){
            return `${BASE_URL}/design/${this.id}/texture-composable/${h}`;
        }
        return `${BASE_URL}/design/0/texture-composable/${this.project.config.size}`;
        return null;
    }
    constructor({project, ...rest}) {
        if(!project){
            throw Error('proejct is undefined')
        }
        this.project = project;
        this.report = new Report({
            design: this,
        })
        makeAutoObservable(this);
        this.fromJSON(rest);

        if(this.enfocus_status === EnfocusStatus.ENFOCUS_UPLOADED_TO_FTP){
            this.enablePulling();
        }
    }

    @computed get options(){
        if(this.project.selectedMaterial?.quantity?.length){
            return this.project.selectedMaterial.quantity;
        }
        return [];
    }

    @computed get textures(){

        // if(!this.file_path){
        //     return null;
        // }

        if(this.project?.store){
            const { selectedMaterialId:mid, selectedFinishId:fid } = this.project;
            if(mid === 1){
                return {
                    mainTexture: this.templateUrlFull,
                    metalnessTexture: this.templateMetallictUrl
                }
            }
            if(mid === 2 || mid === 5){
                return {
                    mainTexture: this.templateUrlFull,
                }
            }
            if(mid === 3){
                return {
                    mainTexture: this.templateUrlFull,
                    opacityTexture: this.templateTransparentUrl
                }
            }
            if(mid === 4){
                return {
                    mainTexture: this.templateComposableUrl,
                    opacityTexture: this.templateTransparentUrl
                }
            }
            return {
                mainTexture: this.templateUrl,
                opacityTexture: null
            }
        }
    }

    @computed get hasUploadedFile() {
        return Boolean(this.file_path);
    }
    @computed get hasFile() {
        return (Boolean(this.file) && this.file.size > 0) || this.file_path;
    }
    @computed get fileName() {
        if(this.file?.name){
            return this.file.name;
        }
        return this.name;
    }
    @computed get fileSize() {
        if(this.file?.size){
            return this.file.size;
        }
        return this.size;
    }



    @action async updateQuantity(q) {
        this.quantity = q;
        try {
    
            const response = await saveDesign(this.toJSON());
    
            if(response.data.success){
                this.file = null;
                this.fromJSON(response.data.design);
                await delay(500);
                await this.reloadTextures();
            }
            
        } catch (err) {
            console.error(err);
        } finally{
            
            // this.uploading = false;

        }
    }

    @action async updateFile(file) {
        // console.log('updateFile', { file });
        this.file = file;
        this.progress = 0;
        this.uploading = true;
        this.failedUpload = false;
        this.disablePulling();
        try {
    
            const response = await uploadDesignFile({
                id: this.id,
                file: file,
                onProgress: action(p=>{
                    // console.log(p);
                    this.progress = p.loaded / p.total;
                })
            });
    
            if(response.data.success){
                this.file = null;
                this.fromJSON(response.data.design);
                await delay(1500);
                await this.reloadTextures();
                this.enablePulling();
            }
            
        } catch (err) {
            console.error(err);
            this.failedUpload = true;
        } finally{
            
            this.uploading = false;

        }
        
    }

    @action async reloadTextures(){
        const sf = this.project.sf;
        // if(this.templateUrl){
            await sf.loadTextures([
                this.templateUrl,
                this.templateUrlFull,
                this.templateMetallictUrl,
                this.templateTransparentUrl,
                this.templateComposableUrl 
            ]);
        // }
        return this.project.render();
    }

    @action async deleteFile(){
        
        this.disablePulling();
        const response = await deleteDesignFile({
            id: this.id,
        });

        if(response.data.success){
            this.fromJSON(response.data.design);
            this.reloadTextures();
        }
        
    }
    @computed get isValid() {
        return this.hasUploadedFile && this.quantity > 0 && this.report.hasSuccess;
    }

    toJSON(){
        return {
            id: this.id,
            quantity: this.quantity
        };
    }
    @action fromJSON(json){
        const {name, file_path, id, size = 0, quantity = '', report, enfocus_status = null} = json;
        this.name = name;
        this.file_path = file_path;
        this.id = id;
        this.size = size;
        this.enfocus_status = enfocus_status;
        this.quantity = parseInt(quantity) || '';
        this.report.fromJSON(report)
    }



    
    @observable isPullingEnabled = false;
    timeoutId; 
    @action enablePulling(){
        this.isPullingEnabled = true;
        this.retriveData();
    }
    @action disablePulling(){
        this.isPullingEnabled = false;
        clearTimeout(this.timeoutId);
    }
    retriveData = () => {
        this.reload();

        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.retriveData, 5000);
    }

    async reload(){
        try {
            const response = await loadDesign({id: this.id});
            if(response.data.success){
                this.fromJSON(response.data.design);
                const hasEnfocusResponse = [EnfocusStatus.ENFOCUS_RESPONSE_SUCCESS, EnfocusStatus.ENFOCUS_RESPONSE_ERROR].includes(this.enfocus_status);
                if(hasEnfocusResponse){
                    this.disablePulling();
                }

            }
        } catch (err) {
            console.error(err);
        }
    }

}
