

export function Accordion({ children, title }) {
    return <div className="Accordion">
        <div className="Accordion__title">
            {title}
        </div>
        <div className="Accordion__content">

            {children}
        </div>
    </div>
}