import { observer } from "mobx-react-lite";
import ProjectClass from "../models/ProjectClass";
import { formatCurrency } from "../utils/Currency";
import { __ } from "../utils/translation";
import './TableSummary.scss'


const TableSummary = observer(
    /**
     * @param {{project:ProjectClass}}
     */
    function ({ project }) {

        return (
            <div className="TableSummary">
                <div className="TableSummary__total">
                    <div className="TableSummary__total-name">
                        {__('table_total_quantity')}
                    </div>
                    <div className="TableSummary__total-price">
                        {project.totalQuantity} {__('pieces')}
                    </div>
                </div>
                <ol>
                    <li className="">
                        <div className="TableSummary__item">

                            <div className="TableSummary__item-label">
                                {__('addons_title')}
                            </div>
                            <div className="TableSummary__item-price">
                                {/* {formatCurrency(project.totalAddons)} */}
                            </div>
                        </div>

                        <ol>
                            {
                                project.addonsSummary.map(addon => {

                                    return <li key={addon.id} className="">
                                        <div className="TableSummary__item">

                                            <div className="TableSummary__item-label">
                                                {__(`addon${addon.id}_title`)}
                                                <div className="TableSummary__item-desc">
                                                    {addon.price > 0 && <>
                                                        {formatCurrency(addon.price)}/{__('pieces')}
                                                    </>}
                                                    {addon.price === 0 && <>
                                                        {__('FREE')}
                                                    </>}
                                                </div>
                                            </div>
                                            <div className="TableSummary__item-price">
                                                {formatCurrency(addon.totalPrice)}
                                            </div>
                                        </div>
                                    </li>
                                })
                            }
                        </ol>
                    </li>

                    {project.designs.filter(d => d.quantity).length > 0 &&
                        <div className="TableSummary__item">

                            <div className="TableSummary__item-label">
                                {__('Pouches')}
                            </div>
                            <div className="TableSummary__item-price">
                                {formatCurrency(project.totalBags)}
                            </div>
                        </div>
                    }

                </ol>

                <div className="TableSummary__total last">
                    <div className="TableSummary__total-name">
                        {__('table_total_price')}
                    </div>
                    <div className="TableSummary__total-price">
                        {formatCurrency(project.totalPrice)}
                    </div>
                </div>
            </div >
        )
    })
TableSummary.displayName = 'TableSummary'

export default TableSummary