
var formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

export function initFormatter(lang, options){
    formatter = new Intl.NumberFormat(lang, options);
}

export function formatCurrency(val, {hideBlank = false} = {}){
    
    if(val > 0 || !hideBlank){
        return formatter.format(val);
    }
    return '';
}

