import { observer } from "mobx-react-lite";
import { PUBLIC_URL } from "../App";
import { Accordion } from "../components/Accordion";
import { AccordionItem } from "../components/AccordionItem";
import { BubbleButton } from "../components/BubbleButton";
import { Button } from "../components/Button";
import { Info } from "../components/Info";
import InfoBar from "../components/InfoBar";
import { Link } from "../components/Link";
import PdfDownload from "../components/PdfDownload";
import SketchfabRender from "../components/SketchfabRender";
import { SmallCard } from "../components/SmallCard";
import { SwiperList } from "../components/SwiperList";
import TableSummary from "../components/TableSummary";
import TextureUpload from "../components/TextureUpload";
import PlusIcon from "../icons/PlusIcon";
import { store } from "../stores/Store";
import { __ } from "../utils/translation";

const DesignsStep = observer((props) => {
    return <>
        <div className="d-none d-lg-block">

            <p className="form-header" dangerouslySetInnerHTML={{ __html: __('header_design_step') }}>

            </p>
        </div>
        <InfoBar
            title={<span dangerouslySetInnerHTML={{ __html: __('unlimited') }}></span>}
        >
            <p>{__('text_design')}</p>
        </InfoBar>

        <PdfDownload title={__('btn_template_download')} href={store.templateUrl} note={`500KB`} />

        {store.project.designs.map((d, i, arr) => {
            const index = arr.length > 1 ? i + 1 : 0;
            return <TextureUpload
                index={index}
                key={d.id}
                active={d.id === store.project.currentDesignId}
                item={d}
                onRemove={e => {
                    store.project.removeDesign(d);
                }}
                onPreview={e => {
                    store.project.switchToDesign(d.id);
                }}
            />

        })}

        <Link style={{ margin: '0rem 0 1rem' }} type="button" className="fs-14 btn-link " onClick={async e => {
            store.project.addDesign();
        }}>
            <PlusIcon style={{ marginTop: -3 }} /> {__('add_design')}
        </Link>

        <hr className="mb-4 mt-2" />

        <TableSummary project={store.project} />

        {/* <div className="buttons-row">
            <Button className="primary" disabled>Confirm &amp; Continue</Button>
        </div> */}

    </>
})
DesignsStep.displayName = 'DesignsStep'
export default DesignsStep