import {
    observable,
    makeAutoObservable,
    action,
    computed,
    autorun
} from 'mobx'
import ProjectClass from '../models/ProjectClass'
import SketchfabControllerClass from '../utils/SketchfabController';
import config1 from '../configs/config.js'
import {
    debounce, delay, throttle
} from '../utils/Events';
import {
    publicUrl, webUrl
} from '../utils/url';
import { simpleRouter, SimpleRouterClass } from '../utils/Router';
import ArManagerClass from '../utils/AR';
 import {isIOS, isMobileDevice} from '../utils/Device';
import { getProduct } from '../utils/api';
import { initFormatter } from '../utils/Currency';
import { swalConfirm } from '../utils/Swal';
import { __ } from '../utils/translation';

const QRCode = require('qrcode')
const BREAKPOINT_XL = 1200;
const PUBLIC_URL = process.env.PUBLIC_URL || '/';

/**
 * @typedef {{id:Number, requiredMaterial:Array, code:String, title:String}} FinishData
 * @typedef {{id:Number, requiredMaterial:Array, title:String}} AddonData
 * @typedef {{id:Number, material:String, title:String}} MatData
 */


export class Step {
    static FEATURES = 'features'
    static DESIGN = 'design'
}

class StoreClass {
    sf: SketchfabControllerClass;
    title = "test"
    step = Step.FEATURES
    isAccordionOpenByDefault = window.innerWidth > BREAKPOINT_XL;
    isAccordionInteractive = window.innerWidth < BREAKPOINT_XL;

    SimpleRouterClass
    project: ProjectClass
    ar: ArManagerClass
    @observable pendingRequest = true
    @observable error = ''

    @computed get firstStepValid() {
        return Boolean(this.project.selectedMaterialId);
    }
    @computed get designStepValid() {
        return this.project.designs.filter(d => d.isValid).length === this.project.designs.length
    }




   

    constructor() {
        makeAutoObservable(this)
        this.project = new ProjectClass({
            store: this
        });
        this.router = simpleRouter;
        this.ar = new ArManagerClass({
            project: this.project
        });


        
        window.addEventListener('resize', this.onResize, { passive: true });
        window.addEventListener('scroll', this.onScroll, { passive: true });
        this.onResize();

        autorun(()=>{
            if(this.lightBgEnabled){
                document.documentElement.classList.add('light-bg');
                if(this.project?.sf){
                    this.project.sf.setBackground({color:[255/255,209/255,36/255]});
                }
            }else{
                document.documentElement.classList.remove('light-bg');
                if(this.project?.sf){
                    this.project.sf.setBackground({color:[1,1,1]});
                }

            }

        })
        
        setTimeout(()=>{
            this.loadConfiguration();
            this.onResize();
        },200);
    }

    @computed get templateUrl():string{
        var url = publicUrl(`templates/${this.project.templateSize}/${this.project.templateSize}`);
        var addons = [];
        if(this.project._hasAddon(3)){ //
            addons.push('tearnotch')
        }
        if(this.project._hasAddon(2)){ //
            addons.push('zip')
        }
        if(this.project._hasAddon(1)){ //
            addons.push('roundedcorners')
        }
        if(this.project._hasAddon(5)){ //
            addons.push('valve')
        }
        if(this.project._hasAddon(4)){ //
            addons.push('eurohole')
        }
        if(addons.length>0){
            url += '_' + addons.join('_');
        }
        url += '.pdf';
        return url;
    }

    @observable currentLangCode = (new URLSearchParams(window.location.search)).get('lang') || 'en';
    /**
     * @var {"EUR"|"DKK"}
     */
    @observable currentCurrency = 'EUR';
    @computed get currentLangName(){
        return String(this.currentLangCode).toUpperCase()
    }
    @observable languages = [
        {name: 'English', id: 'en'},
        {name: 'Danish', id: 'dk'},
    ];
    @action switchLang(code){
        this.currentLangCode = code;
        const hash = this.project.hash;
        const size = this.project.size;
        const product_id = store.project.product_id;

        if (hash && size && product_id) {
            simpleRouter.replaceState({ hash: hash, size: size }, null, `/${size}/${product_id}/${hash}/?lang=${this.currentLangCode}`);
        }
    }


    @action showError(err){
        this.error = err;
        throw new Error(err);
    }

    @action async loadConfiguration(){
        try {
            await this.project.loadConfiguration();
            
            if(this.project.designs.some(d=>d.hasUploadedFile)){
                this.step = Step.DESIGN;
            }
        
            
        } catch (err) {
            console.error(err);
        } finally{
            this.pendingRequest = false;
        }
        
    }
    @action initCurrency(){
        // if(this.currentLangCode === 'en'){
            if(this.currentCurrency !== 'DKK'){
                initFormatter('de-DE', { style: 'currency', currency: 'EUR', compactDisplay: "short" });
            }else{
                initFormatter('da', { style: 'currency', currency: 'DKK', compactDisplay: "short" });
            }
    }

    @computed get webUrl(){
        if(this.currentLangCode !== 'en'){
            return webUrl('da/#createyourpouch')
        }
        return webUrl('#createyourpouch')
    }

    @computed get backUrl(){
        if(this.currentLangCode !== 'en'){
            return webUrl('da')
        }
        return webUrl('')
    }

    @computed get helpLink(){
        if(this.currentLangCode !== 'en'){
            return webUrl('da/design-services/professional-design-services/')
        }
        return webUrl('design-services/professional-design-services/')
    }


    @action onResize = debounce((e) => {
        const renderTopOffset = document.querySelector('.SketchfabRender')?.getBoundingClientRect()?.top || 0;
        window.document.documentElement.style = `--scroll-y: ${window.scrollY}px; --height-y: ${window.innerHeight}px; --render-top-offset: ${renderTopOffset}px;`;
    
        
        this.isAccordionOpenByDefault = window.innerWidth > BREAKPOINT_XL;
        this.isAccordionInteractive = window.innerWidth < BREAKPOINT_XL;
    }, 50)
    @action onScroll = debounce((e) => {
        const renderTopOffset = document.querySelector('.SketchfabRender')?.getBoundingClientRect()?.top || 0;
        window.document.documentElement.style = `--scroll-y: ${window.scrollY}px; --height-y: ${window.innerHeight}px; --render-top-offset: ${renderTopOffset}px;`;
    }, 50)


    @action async switchStep(step) {
        const hasUploadedDesigns = this.project.designs.some(d=>d.hasFile || d.hasUploadedFile);
        if(hasUploadedDesigns){
            const response = await swalConfirm({
                showConfirmButton: true, 
                showCancelButton: true, 
                title:' ',
                text: __('changing_template'),
                cancelButtonText: __('changing_template_btn_no'),
                confirmButtonText: __('changing_template_btn_ok')
            });
            if(response?.isConfirmed){
                this.project.designs.forEach(d=>{
                    if(d.hasUploadedFile){
                        d.deleteFile();
                    }
                });

                this.step = step;
            }
        }else{
            this.step = step;
        }

        // await delay(50);
        window.scrollTo(0,0);
        this.onResize();

    }
    @action addUpload(step) {
        this.project.addDesign();
    }

    @observable arModalVisible = false;
    @observable arLoading = false;
    @action async onCreateAr(e){
        try {
            this.arLoading = true;
            this.showArModal();
            const response = await this.ar.createAr();
            if(response?.success){
                if(isMobileDevice() || isIOS()){
                    window.location.href = this.ar.arModelUrl;
                }else{
                    this.createQrCode(this.ar.arModelUrl);
                }
            }

        } catch (err) {
            
        }finally{
            this.arLoading = false;
        }
    }
    @action createQrCode(url){
        const canvas = document.getElementById('QrModalCanvas');

        if(canvas){
            setTimeout(()=>{
                QRCode.toCanvas(canvas, url, {width: 180, height:180}, function (error) {
                    if (error){
                        console.error(error)
                    } else{
                        // console.log('QR code created!');
                    }
                });
            },100);
        }

    }
    @action showArModal(){
        this.arModalVisible = true;
    }
    @action hideArModal(){
        this.arModalVisible = false;
    }



    @observable isMaterialModalVisible = false
    @action showMaterialModal(){
        this.isMaterialModalVisible = true;
    }
    @action hideMaterialModal(){
        this.isMaterialModalVisible = false;
    }


    @observable isAddonModalVisible = false
    @action showAddonModal(){
        this.isAddonModalVisible = true;
    }
    @action hideAddonModal(){
        this.isAddonModalVisible = false;
    }




    @observable lightBgEnabled = true
    @action updateLightBg(checked){
        this.lightBgEnabled = checked;
        this.project.onBackgroundChange();
    }
}

const store = new StoreClass();
window['store'] = store;
export {
    store,
    StoreClass
}