import { observer } from "mobx-react-lite";
import { __ } from "../utils/translation.js";

import Dropzone from "./Dropzone.jsx";


const DefaultDropzone = observer(({ onDrop, ...props }) => {
    return <div className="DefaultDropzone">
        <Dropzone onDrop={onDrop}  {...props}>
            <div className="text-cente">

                <p className="DefaultDropzone__title" dangerouslySetInnerHTML={{ __html: __('drop_file') }}></p>
                <p className="DefaultDropzone__note">{__('max_filesize')}: 200MB</p>
            </div>
        </Dropzone>
    </div>
})

DefaultDropzone.displayName = 'DefaultDropzone'

export default DefaultDropzone