import { publicDir, publicUrl } from "../utils/url";



export default function Preloader({ visible = false }) {
    if (visible) {

        return <div className="main-preloader">
            <iframe allowtransparency="true" className="main-preloader__iframe" src={publicUrl('preloader.html')} frameBorder="0"></iframe>
        </div>
    }
    return null;
}