

export default {
    // sketchfabId: "5cf5aafd5ed04532b171d209cfa811d2",
    sketchfabId: "f052e1758dbd4c7abc284af74fc22db8",
    type: 'Side-gussets',
    size:"195x275x95",
    templateSize:"195x275x95",
    cameraPos: [0, -0.6, 0.1],
    cameraTarget: [0, 0, 0.12],
    product_id: 835,
    fov: 90,
    normalMaptexCoordUnit: 6,
    defaultCoordUnit: 3,
    sizes:[
        "140x210x70",
        "150x230x80",
        "170x470x125",
        "195x275x95",
        "210x490x125",
        "215x750x125"
    ],
    
    naormalMap:[
        {
            id: 'clear',
            name: 'Side-gussets-plastic-clear_NRM.jpg', 
        },
        {
            id: 'TN',
            name: 'Side-gussets-plastic-TN_NRM.jpg', 
        },
        {
            id: 'TN_V',
            name: 'Side-gussets-plastic-TN_V_NRM.jpg'
        },
        {
            id: 'V',
            name: 'Side-gussets-plastic-V_NRM.jpg'
        }
    ],
    addons:[
        {
            id: 1,
            title: 'Rounded corners',
            show:[],
            hide:["{size}.010"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 2,
            title: 'Zip lock',
            show:['{size}.zip'],
            hide:[],
            requiredMaterial: []
        },
        {
            id: 3,
            title: 'Tear Notch',
            show:[],
            hide:["{size}.001"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 4,
            title: 'Euro hole',
            show:[],
            hide:["{size}.100"],
            requiredMaterial: []
        },
        {
            id: 5,
            title: 'Valve',
            show:[],
            hide:[],
            requiredMaterial: [1,2,3]
        },

    ],
    materials:[
        {
            id: 1,
            title: 'Recyclable metalic plastic',
            material: 'M_metallic_plastic',
            image: '/assets/bags/side-gusset/metalic.png'
        },
        {
            id: 2,
            title: 'Recyclable white plastic',
            material: 'M_white_plastic',
            image: '/assets/bags/side-gusset/recyclable-white-plastic.png'
        },
        {
            id: 3,
            title: 'Recyclable transparent plastic',
            material: 'M_transparent_plastic',
            image: '/assets/bags/side-gusset/recyclable-transparent-plastic.png'
        },
        {
            id: 4,
            title: 'Home compostable',
            material: 'M_compostable_brown_paper',
            image: '/assets/bags/side-gusset/compostable.png'
        },
        {
            id: 5,
            disabled: true,
            title: 'Recyclable paper (plastic free)',
            material: 'M_paper',
            image: '/assets/bags/side-gusset/paper-plastic-free.png'
        }
    ]
}