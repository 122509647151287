import React, { Component } from "react";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
// import 'tippy.js/themes/material.css'; // optional for styling
import './Tooltip.scss'

export default function Tooltip({ title, children }) {
    return <Tippy content={title} trigger={'touchstart mouseenter focus'} hideOnClick={true} touch={false}>
        {children}
    </Tippy>
}