import { observer } from "mobx-react-lite";
import { PUBLIC_URL } from "../App";
import { Accordion } from "../components/Accordion";
import { AccordionItem } from "../components/AccordionItem";
import { BubbleButton } from "../components/BubbleButton";
import { Button } from "../components/Button";
import { Info } from "../components/Info";
import InlineWarning from "../components/InlineWarning.jsx";
import SketchfabRender from "../components/SketchfabRender";
import { SmallCard } from "../components/SmallCard";
import { SwiperList } from "../components/SwiperList";
import { Checked } from "../icons/Checked";
import { Step, store } from "../stores/Store";
import { formatCurrency } from "../utils/Currency";
import { __ } from "../utils/translation";

function CheckedIcon({ checked }) {
    if (checked) {
        return <span className="checked-icon"><Checked /></span>
    }
    return null;
}

const FeaturesStep = observer((props) => {

    const project = store.project;

    return <>
        <p className="form-header d-none d-lg-block" dangerouslySetInnerHTML={{ __html: __('header_features') }}>

        </p>
        <Accordion

        >
            <AccordionItem
                interactive={store.isAccordionInteractive}
                isOpen={store.isAccordionOpenByDefault}
                title={<><CheckedIcon checked={project.selectedMaterial} />{__('material_title')} <Info onClick={e => {
                    store.showMaterialModal();
                }} title={__('material_tooltip')} /></>}>
                <div className="row">
                    <div className="col">
                        <SwiperList
                            items={project.materials}
                            renderItem={(item, index) => {
                                return <div style={{ padding: 5 }}>
                                    <SmallCard
                                        title={__(item.code)}
                                        image={item.image}
                                        disabled={!!item.disabled}
                                        checked={project.selectedMaterialId === item.id}
                                        onClick={e => {
                                            project.selectMaterial(item.id)
                                        }} />
                                </div>

                            }}
                        >

                        </SwiperList>
                        {/* info ma się włączać dla toreb kompostowalnych lub papier recyklingowy */}
                        {
                            [4, 5].includes(project.selectedMaterialId) ?
                                <InlineWarning>{__('heat_warning')}</InlineWarning> : null
                        }
                    </div>
                </div>
            </AccordionItem>
            <AccordionItem
                interactive={store.isAccordionInteractive}
                isOpen={store.isAccordionOpenByDefault}
                title={<><CheckedIcon checked={project.selectedFinish} />{__('finish_title')} <Info onClick={e => {

                }} title={__('finishing_tooltip')} /></>}>
                <div className="row">
                    <div className="bubble-list">
                        {project.finishes.map((f, i) => {
                            return <BubbleButton
                                disabled={!project.validFinishes.find(ff => f.id === ff.id)}
                                onClick={e => {
                                    project.selectFinish(f.id)
                                }}
                                key={f.id}
                                checked={f.id === project.selectedFinishId}
                            >{__(`finish${f.id}_title`)}</BubbleButton>
                        })}
                    </div>
                </div>
            </AccordionItem>
            <AccordionItem
                interactive={store.isAccordionInteractive}
                isOpen={store.isAccordionOpenByDefault}
                title={<><CheckedIcon checked={project.selectedAddonsIds.length} />{__('addons_title')} <Info onClick={e => {
                    store.showAddonModal();
                }} title={__('addon_tooltip')} /></>}>
                <div className="row">
                    <div className="bubble-list bubble-list--full">
                        {project.addons.map((a, i) => {
                            var price = a.price ? `+${formatCurrency(a.price)} / ${__('piece')}` : __('FREE')
                            return <BubbleButton
                                disabled={!project.validAddons.find(aa => aa.id === a.id)}
                                onClick={e => {
                                    project.toggleAddon(a);
                                }} key={a.id}
                                checked={project.selectedAddonsIds.includes(a.id)}
                                info={price}
                            >{__(`addon${a.id}_title`)}</BubbleButton>
                        })}
                    </div>
                </div>
            </AccordionItem>

        </Accordion>

        <div className="buttons-row">
            <Button onClick={e => {
                store.switchStep(Step.DESIGN);
                store.project.save();
            }} className="primary" disabled={!store.firstStepValid}>{__('btn_confirm_continue')}</Button>
        </div>
    </>

})
FeaturesStep.displayName = 'FeaturesStep'
export default FeaturesStep