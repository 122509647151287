

const translation = {
    currency: 'DKK',
    btn_back: 'Tilbage',

    ar_header: 'Scan koden og åbn AR',
    ar_text: 'Peg dit smartphonekamera på denne QR kode og se din pose i AR',
    ar_close: 'Luk',
    ar_loading: 'Din AR-pose er kun få øjeblikke væk',
    
    "Standup-pouch":"Ståpose",
    "Side-gussets":"Side gussets",

    drop_file: 'Træk din fil her eller <span class="link">upload fra din pc</span>',
    max_filesize: 'Maks. Filstørrelse',
    "menu_pouch": "Lav din egen pose",
    "menu_products": "Varer",
    "menu_markets": "Markeder",
    "menu_design": "Designydelser",
    "menu_sustainability": "Bæredygtighed",
    "menu_resources": "Ressourcer",
    "menu_about": "Om os",
    "placeholder_quantity": "vælg antal",
    "add_design": "Tilføj et andet design",
    "table_total_quantity": "Total ordremængde",
    "table_total_price": "Samlet pris",
    "addons_title": "Tilføjelser",
    "finish_title": "Efterbehandling",
    "material_title": "Materiale",
    "designs_title": "Designs",
    "total_quantity": "Total antal",
    "quantity": "Antal",
    "mat1_title": "Genanvendelig metalliseret plast",
    "mat2_title": "Genanvendelig hvid plast",
    "mat3_title": "Genanvendelig gennemsigtig plast",
    "mat4_title": "Komposterbar",
    "mat5_title": "Genanvendelig papir  (uden plast)",
    "addon_title": "Gasventil",

    addon1_title: 'Afrundede hjørner',
    addon2_title: 'Zip lock',
    addon3_title: 'Afrivningshak',
    addon4_title: 'Euro hole',
    addon5_title: 'Gasventil',

    finish2_title: 'Mat',
    finish1_title: 'Blank',

    "Pouches": "Poser",
    "FREE": "GRATIS",
    "price": "Pris",
    "piece": "stk.",
    "pieces": "stk.",
    "tab_features": "Funktioner",
    "tab_design": "Design og antal",
    "btn_sample_kit": "Gratis prøvepakke",
    btn_watch_tutorial: 'Se vejledningsvideo',
    btn_watch_tutorial_mobile: 'Se vejledningsvideo',
    "btn_save_draft": "Gem et udkast",
    btn_add_to_cart: "Tilføj indkøbskurv",
    btn_update_cart: 'Opdater indkøbskurv',
    help_link:'Har du brug for hjælp med dit design?',
    "btn_confirm_continue": "Fortsæt",
    "btn_view_ar": "Se i AR",
    "btn_template_download": "Design skabelon download",
    "back_to_type_selection": "Ændre type eller størrelse",
    "text_design": "Prisen for ordren er beregnet ud fra den samlede mængde emballage.",
    "header_design_step": "Upload og vælg antal",
    "header_features": "Konfigurér din pose",
    
    "material_tooltip": "Her kan du vælge basismateriale for din stĺpose",
    "finishing_tooltip": "Genanvendelig plast kan have mat eller blank efterbehandling. Du kan vælge hvilken type du vil bestille her.",
    "addon_tooltip": "Her kan du vælge forskellige tilføjelser til din stĺpose",
    heat_warning: 'Digitalt tryk på papir er følsomt over for varme. Der kan forekomme misfarvning på forseglingsområderne ved brug af mørke farver. Vi anbefaler, at du bruger lysere farver på forseglingsområderne, ellers kan der forekommer en let misfarvning der.',


    changing_template: 'Ændring af materiale eller tilvalg kræver, at du fjerner de uploadede designs. Ønsker du at fortsætte?',
    changing_template_btn_ok: 'Ja',
    changing_template_btn_no: 'Nej',

    design: 'Design',
    unlimited: 'Ubegrænset <span class="marked-link">GRATIS</span> designs!',
    unlimited_desc: 'Du kan vælge så mange forskellige kunstværker, du vil, inden for samme ordre',
  
    // modal
    design_processing: 'Filen behandles...',
    design_error: 'Fejl. Se {link} og upload en korrekt fil.',
    design_uploaded: 'Design uploaded korrekt, se ',
    view_report: 'venligst rapporten',
    Download_report_pdf: 'Download rapport.pdf',
    btn_guideline_download: 'Designvejledning download',
    file_guideline_download: 'Lankstinukas_Lprint_dk.pdf',
    modal_header_1: 'Resultater af den tekniske og visuelle kontrol før tryk',
    modal_header_failed: 'Filen blev ikke uploadet',
    Errors: 'Fejl',
    'No errors': 'Ingen fejl',
    modal_header_success: 'Godt gået!',
    modal_body_success: 'Vores automatiserede trykfilskontrol har fundet ingen fejl i dit design. Det ser ud til, at vi er klar til at gå i gang og din ordre er klar til at blive trykt.',
    modal_body_failed: 'Kontrol før tryk viste nogle fejl i den designfil, du uploadede. Du bedes at rette de punkter som er angivet på listen nedenfor og uploade filen igen. Herefter burde din fil være klar til tryk!',
    
    understand: 'jeg forstår',
    material_description: `
    <h3>Materialer og tilvalg </h3>
    <p>Vi har et udvalg af bæredygtige materialer til at dække alle funktionelle krav og forskellige visuelle udtryk. Forskellige tilfvalg kan ændre posens funktionalitet, som f.eks. en genlukkelig lynlås, eller det rent visuelle, som f.eks. afrundede hjørner på posen.</p>

    <h5>Metalliseret plast</h5>
    <p>Højeste niveau af varebeskyttelse, fuldt genanvendelig og plads til kreative metalliske farveeffekter.</p>

    <h5>Hvid plast</h5>
    <p>Genanvendelig struktur med høj beskyttelse mod fugt og er god til at bevare aromaer. Dens enkle sammensætning gør den billigere end konventionelle ikke-genanvendelige poser.</p>
   
    <h5>Gennemsigtig plast </h5>
    <p>Poser med vinduer, så forbrugeren kan se din vare, som er pakket ned i poserne. Højt beskyttelsesniveau og fuldt genanvendelige.</p>

    <h5>Komposterbare poser</h5>
    <p>Den komposterbare pose er fremstillet af vedvarende materialer. Alle komponenter er certificeret som komposterbare og opfylder kriterierne i EN 13432.</p>
    <p>&nbsp;</p>
    
    `,
    addon_description: `
    <h3>Tilvalg     </h3>
    <p>Forskellige tilvalg kan gøre din pose mere alsidig og nem at bruge for dine kunder.    </p>

    <h5>Zip-lock
    </h5>
    <p>Hold dine produkter friske i længere tid og pak dem sikkert ind med en genlukkelig lynlås.

    </p>
    
    <h5>Afrivningshak</h5>
    <p>Afrivningshak gør det nemmere for forburgeren at åbne posen og den guider til den perfekte åbningslinje – ikke for højt så den kommer gennem forseglingszonen og ikke for lavt, så indholdet falder ud eller man river vigtig information i stykker.

    </p>
   
    <h5>Afrundede hjørner
    </h5>
    <p>De afrundede hjørner giver din pose et anderledes udseende og hjælper dig med at skille dig ud fra mængden.

    </p>
    
    <h5>Euro hole</h5>
    <p>Euro hole er en nyttig tilføjelse, hvis du har en lille pose, der skal hænges op i butikken.

    </p>
    
    <h5>Afgasningsventil</h5>
    <p>Afgasningsventil lader kaffegasserne slippe ud af posen, men tillader ikke frisk luft at komme ind i posen. På den måde holdes din kaffe frisk længere og forhindrer, at posen fyldes op med gassen.

    </p>
    <p>&nbsp;</p>
    `,
    upload_size: 'Det ser ud til, at du forsøger at uploade en fil, der er for stor. Den maksimale filstørrelse er 200 MB',
    invalid_file:'Ugyldig filtype. Upload venligst en PDF-fil',

    bright_label: 'Lys',
    neutral_label: 'Neutral',
    builder: 'Posebygger',

    yes_i_do: 'Ja, det vil jeg gerne',
    watch_video_description: `
    <div class="text-center">
        <p>&nbsp;</p>
        <h3 class="watch-video-modal-title">Vil du gerne se en <span class="yellow-text-background">vejledningsvideo?</span></h3>
        <p>&nbsp;</p>
        <p>Vi forstår, at det måske er første gang, du bruger vores posebygger og at tingene kan være uklare. Vi har lavet denne vejledningsvideo for at gøre det nemmere at forstå og guide dig til hvordan du bruger værktøjet.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
    `,
}
window.translation = translation;
export default translation 

