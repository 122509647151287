

import './Switch.scss'

export default function Switch({ checked = false, name = 'undefined', labelLeft, labelRight, onChange }) {
    return <label className="Switch">
        <input onChange={e => {
            onChange(e.target.checked);
        }}
            checked={checked}
            className="Switch__input" type="checkbox" name={name} id={name} />
        <div className="Switch__label left">
            {labelLeft}
        </div>
        <div className="Switch__control">
            {/* control */}
        </div>
        <div className="Switch__label right">
            {labelRight}
        </div>
    </label>
}