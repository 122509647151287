

export default {
    sketchfabId: "7bef5056ca124274a14eab8e835e53e7",
    type: 'Standup-pouch',
    templateSize:'190x260x110',
    size:'190x260x110',
    product_id: 827,
    cameraPos: [0, -0.5, 0.2],
    cameraTarget: [0, 0, 0.1],
    normalMaptexCoordUnit: 6,
    defaultCoordUnit: 1,
    sizes:[
        "120x200x80",
        "160x230x80",
        "190x260x110",
        "200x305x80",
        "250x300x100",
    ],
    naormalMap:[
        {
            id: 'clear',
            name: 'Standup-pouch-plastic-clear_NRM.jpg', 
        },
        {
            id: 'TN',
            name: 'Standup-pouch-plastic-TN_NRM.jpg', 
        },
        {
            id: 'TN_V',
            name: 'Standup-pouch-plastic-TN_V_NRM.jpg'
        },
        {
            id: 'V',
            name: 'Standup-pouch-plastic-V_NRM.jpg'
        }
    ],
    addons:[
        {
            id: 1,
            title: 'Rounded corners',
            show:[],
            hide:["{size}.010"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 2,
            title: 'Zip lock',
            show:['{size}.zip'],
            hide:[],
            requiredMaterial: [1,2,3,4]
        },
        {
            id: 3,
            title: 'Tear Notch',
            show:[],
            hide:["{size}.001"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 4,
            title: 'Euro hole',
            show:[],
            hide:["{size}.100"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 5,
            title: 'Valve',
            show:[],
            hide:[],
            requiredMaterial: [1,2,3]
        },

    ],
    materials:[
        {
            id: 1,
            title: 'Recyclable metalic plastic',
            material: 'M_metallic_plastic',
            image: '/assets/bags/recyclable-metalic-plastic.png'
        },
        {
            id: 2,
            title: 'Recyclable white plastic',
            material: 'M_white_plastic',
            image: '/assets/bags/recyclable-white-plastic.png'
        },
        {
            id: 3,
            title: 'Recyclable transparent plastic',
            material: 'M_transparent_plastic',
            image: '/assets/bags/recyclable-transparent-plastic.png'
        },
        {
            id: 4,
            title: 'Home compostable',
            material: 'M_compostable_brown_paper',
            image: '/assets/bags/home-compostable.png'
        },
        {
            id: 5,
            title: 'Recyclable paper (plastic free)',
            material: 'M_paper',
            image: '/assets/bags/recyclable-paper-plastic-free.png'
        }
    ]
}