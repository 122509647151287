import axios from "axios";
export const BASE_URL = process.env.REACT_APP_API_URL;
export const AR_ENDPOINT = process.env.REACT_APP_AR_ENDPOINT;

const api = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: {'X-Custom-Header': 'foobar'}
});

export async function uploadDesignFile({id, file, onProgress}){
    var progress = {};
    const f = new FormData();
    f.append('upload', file);
    f.append('id', id);

    // const request = fetch({
    //     url: `${BASE_URL}/design/${id}/upload`,
    //     method: 'post',
    //     body: file 
    // });
    return api.request({
        method: "post", 
        url: `/design/upload`, 
        data: f, 
        onUploadProgress: onProgress,
    });

    
}

export async function createAr(json){

    return api.request({
        method: "post", 
        url: AR_ENDPOINT, 
        data: json, 
        headers: {
            'Content-Type': 'application/json'
        }
    });    
}

export async function getProduct(id){

    return api.request({
        method: "get", 
        url: `/shop/product/${id}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });    
}


export async function deleteDesignFile({id}){
    var progress = {};
    const f = new FormData();
    f.append('id', id);

    return api.request({
        method: "post", 
        url: `/design/delete-upload`, 
        data: f, 
    });
}
export async function createProject(json){

    return api.request({
        method: "post", 
        url: `/project`, 
        data: (json),  
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export async function saveDesign(json){
    
    return api.request({
        method: "post", 
        url: `/design/save`, 
        data: json,  
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export async function deleteDesign(id){
    
    return api.request({
        method: "delete", 
        url: `/design/${id}`, 
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export async function updateProject(json){

    return api.request({
        method: "post", 
        url: `/project/${json.hash}`, 
        data: (json),  
        headers: {
            'Content-Type': 'application/json'
        }
    });

    
}
export async function loadProject(hash){
    // var progress = {};
    // const f = new FormData();
    // f.append('upload', file);

    // const request = fetch({
    //     url: `${BASE_URL}/design/${id}/upload`,
    //     method: 'post',
    //     body: file 
    // });
    return api.request({
        method: "get", 
        url: `/project/${hash}`,  
        headers: {
            'Content-Type': 'application/json'
        }
    });

    
}

export async function addToCartShop(json){
    return api.request({
        method: "post",
        url: '/shop/add-to-cart',
        data: json,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function saveAsDraftShop(json){
    return api.request({
        method: "post",
        url: '/shop/save-as-draft',
        data: json,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


export async function loadDesign({id}){
    
    return api.request({
        method: "get", 
        url: `/design/${id}`,  
        headers: {
            'Content-Type': 'application/json'
        }
    });

    
}
// export async function getUploadProgress(request){
//     // Get amount of bytes we're about to transmit
//     const bytesToUpload = request.headers.get('content-length');

//     // Create a reader from the request body
//     const reader = request.body.getReader();

//     // Cache how much data we already send
//     let bytesUploaded = 0;

//     // Get first chunk of the request reader
//     let chunk = await reader.read();

//     // While we have more chunks to go
//     while (!chunk.done) {
//         // Increase amount of bytes transmitted.
//         bytesUploaded += chunk.value.length;

//         // Inform user how far we are
//         status.innerText = 'Uploading (' + (bytesUploaded / bytesToUpload * 100).toFixed(2) + ')...';

//         // Read next chunk
//         chunk = await reader.read();
//     }
// }