
import { Checked } from '../icons/Checked';
import './BubbleButton.scss';


function BubbleButton({ children, info, checked = false, onClick, disabled = false }) {
    var classes = '';
    if (checked) {
        classes += ` checked `;
    }
    return <button disabled={disabled} type='button' onClick={onClick} className={`BubbleButton ${classes}`}>
        <div className="BubbleButton__checked">
            <Checked />
        </div>
        <div className="BubbleButton__label">{children}</div>
        <div className="BubbleButton__info">{info}</div>
    </button>
}

export {
    BubbleButton
}