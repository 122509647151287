

export default {
    sketchfabId: "5cf5aafd5ed04532b171d209cfa811d2",
    // sketchfabId: "b6deb5a296b942728ac24cb68c004bc0",
    hide:[
        "160x230x80",
        "190x260x110",
        "200x305x80",
        "250x300x100",
        "Shadow-160x230x80",
        "Shadow-190x260x110",
        "Shadow-200x305x80",
        "Shadow-250x300x100"
    ],
    naormalMap:[
        {
            id: 'clear',
            name: 'Standup-pouch-plastic-clear_NRM.jpg', 
        },
        {
            id: 'TN',
            name: 'Standup-pouch-plastic-TN_NRM.jpg', 
        },
        {
            id: 'TN_V',
            name: 'Standup-pouch-plastic-TN_V_NRM.jpg'
        },
        {
            id: 'V',
            name: 'Standup-pouch-plastic-TN_V_NRM.jpg'
        }
    ],
    addons:[
        {
            id: 1,
            title: 'Rounded corners',
            code:'addon1_title',
            show:[],
            hide:["120x200x80.010"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 2,
            title: 'Zip lock',
            code:'addon2_title',
            show:['120x200x80.zip'],
            hide:[],
            requiredMaterial: [1,2,3,4]
        },
        {
            id: 3,
            title: 'Tear Notch',
            code:'addon3_title',
            show:[],
            hide:["120x200x80.001"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 4,
            title: 'Euro hole',
            code:'addon4_title',
            show:[],
            hide:["120x200x80.100"],
            requiredMaterial: [1,2,3,4,5]
        },
        {
            id: 5,
            title: 'Valve',
            code:'addon5_title',
            show:[],
            hide:[],
            requiredMaterial: [1,2,3]
        },

    ],
    materials:[
        {
            id: 1,
            title: 'Recyclable metalic plastic',
            material: 'M_metallic_plastic',
            image: '/assets/bags/recyclable-metalic-plastic.png'
        },
        {
            id: 2,
            title: 'Recyclable white plastic',
            material: 'M_white_plastic',
            image: '/assets/bags/recyclable-white-plastic.png'
        },
        {
            id: 3,
            title: 'Recyclable transparent plastic',
            material: 'M_transparent_plastic',
            image: '/assets/bags/recyclable-transparent-plastic.png'
        },
        {
            id: 4,
            title: 'Home compostable',
            material: 'M_compostable_brown_paper',
            image: '/assets/bags/home-compostable.png'
        },
        {
            id: 5,
            title: 'Recyclable paper (plastic free)',
            material: 'M_paper',
            // material: 'M_Stand-up-pouches-paper-TN_V',
            // material: 'M_Stand-up-pouches-paper-TN_V',
            image: '/assets/bags/recyclable-paper-plastic-free.png'
        }
    ]
}