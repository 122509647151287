// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
/**
 * @type {import('sweetalert2').SweetAlertOptions}
 */
const defaultOptions = {
    buttonsStyling: false,
    customClass:{
        cancelButton: 'btn primary',
        confirmButton: 'btn primary'
    }

}

export function swalAlert(options){
    return Swal.fire({
        ...defaultOptions,
        ...options
    });
}
/**
 * 
 * @param {import('sweetalert2').SweetAlertOptions} options 
 * @returns 
 */
export function swalConfirm(options){
    return Swal.fire({
        ...defaultOptions,
        ...options
    });
}