import { observer } from "mobx-react";
import { Design } from "../models/Design";
import PdfDownload from "./PdfDownload";

import ErrorIcon from '../icons/ErrorIcon.jsx';
import WarningIcon from '../icons/WarningIcon.jsx';
import SuccessIcon from '../icons/SuccessIcon.jsx';
import { __ } from "../utils/translation";

import './ReportModal.scss';
import { Modal } from "./Modal";
import { publicUrl } from "../utils/url";
import { DownloadIcon } from "../icons/DownloadIcon";
import { useEffect } from "react";


const ReportModal = observer(props => {
    const design: Design = props.design;
    const report = design?.report;


    if (!report) {
        return null;
    }
    const store = design?.project?.store;

    return <Modal
        className="ReportModal"
        onClose={e => {
            report.hide();
        }}
        isOpen={report.isOpen}

    >

        {/* {design.report.isLoading && <Loader className='static' visible={true} />} */}

        <div className="row gx-4">
            <div className="ReportModal__preview col col-12 col-lg-5">
                <img src={report.image} alt="" />
            </div>
            <div className="col col-12 col-lg-7">
                <div className="form-header">
                    {report.title}
                </div>
                <p>{report.body}</p>
                <p>
                    <a className="link-smaller" href={store.helpLink} target={'_blank'}>{__('help_link')}</a>
                </p>


                <PdfDownload title={__('btn_template_download')} href={store.templateUrl} note={`6MB`} />
                <PdfDownload title={__('btn_guideline_download')} href={publicUrl(__('file_guideline_download'))} note={`3MB`} />

            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-auto me-auto">
                <div className="form-header">{__('modal_header_1')}</div>
            </div>
            <div className="col-auto pt-2 text-right">
                <a className="link-smaller" href={report.pdfUrl} target={'_blank'}><DownloadIcon style={{ marginTop: -2, display: 'inline-block' }} /> {__('Download_report_pdf')}</a>
            </div>
        </div>
        {/* errors */}

        {report.error_messages.length > 0 &&
            <div className="row">
                <div className="col">
                    <div className="ReportModal__header">
                        <div className="ReportModal__header-icon">
                            <ErrorIcon />
                        </div>
                        <div className="ReportModal__header-text">
                            {__('Errors')}
                        </div>
                    </div>
                    {report.error_messages.map((message, i) => <p key={i}>{message}</p>)}
                    {/* <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
                </p> */}
                </div>
            </div>
        }
        {/* warnings */}
        {report.warning_messages.length > 0 &&
            <div className="row">
                <div className="col">
                    <div className="ReportModal__header">
                        <div className="ReportModal__header-icon">
                            <WarningIcon />
                        </div>
                        <div className="ReportModal__header-text">
                            {__('Warnings')}
                        </div>
                    </div>
                    {report.warning_messages.map((message, i) => <p key={i}>{message}</p>)}
                    {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
                </div>
            </div>
        }
        {/* success */}
        {report.fixes_messages.length > 0 &&
            <div className="row">
                <div className="col">
                    <div className="ReportModal__header">
                        <div className="ReportModal__header-icon">
                            <SuccessIcon />
                        </div>
                        <div className="ReportModal__header-text">
                            {__('No errors')}
                        </div>
                    </div>
                    {report.fixes_messages.map((message, i) => <p key={i}>{message}</p>)}
                    {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
                </div>
            </div>
        }

    </Modal>
})

ReportModal.displayName = 'ReportModal'

export default ReportModal