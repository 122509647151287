import { useEffect } from 'react';
import './VideoModal.scss';

import { hideAll } from 'tippy.js';

export const PUBLIC_URL = process.env.PUBLIC_URL;

function VideoModal({ children, footer = null, isOpen = false, onClose, className = '' }) {
    var classes = `${className} `;


    useEffect(() => {

        if (isOpen) {
            window.document.documentElement.classList.add('has-modal');
            hideAll();
        } else {
            window.document.documentElement.classList.remove('has-modal');
        }


    }, [isOpen])

    if (isOpen) {
        classes += ' is-open';
    }
    return (
        <div className={`Modal__overlay_video ${classes}`}>
            <div>
                <button type='button' onClick={onClose} className="Modal__close_video" aria-label='Close'>
                    <img src={PUBLIC_URL + '/assets/modal/video-close-btn.svg'} alt="X" />
                </button>
            </div>
            <div className="Modal__content_video">
                {children}
            </div>
        </div>
    )
}

export {
    VideoModal
}