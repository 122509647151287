

// console.log(process.env);
const PUBLIC_URL = process.env.PUBLIC_URL;
const REACT_APP_APS_URL = process.env.REACT_APP_APS_URL;
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_WEB_URL = process.env.REACT_APP_WEB_URL;

export function webUrl(url = ''){
    return REACT_APP_WEB_URL + url;
}

export function publicDir(url = ''){
    return PUBLIC_URL + url;
}
export function publicUrl(url = ''){
    return REACT_APP_APS_URL + url;

}
export function absolutePublicUrl(url = ''){
    return REACT_APP_APS_URL + url;
}

export function designFileUrl(id, filePath) {
    return REACT_APP_API_URL + '/design/get/'+id+"/"+filePath;
}