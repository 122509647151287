import { publicDir, publicUrl } from "../utils/url";

import './OverlayCover.scss';

export default function OverlayCover({ visible = false }) {
    if (visible) {

        return <div className="OverlayCover">
        </div>
    }
    return null;
}