import { action, computed, has, makeAutoObservable, observable, toJS } from "mobx";
import config1 from "../configs/config2";
import { StoreClass } from "../stores/Store";
import {
    addToCartShop,
    createProject,
    deleteDesign,
    getProduct,
    loadProject, 
    saveAsDraftShop,
    saveDesign,
    updateProject
} from "../utils/api";
import { formatCurrency } from "../utils/Currency";
import { debounce, delay } from "../utils/Events";
import { simpleRouter } from "../utils/Router";
import SketchfabControllerClass from "../utils/SketchfabController";
import { uid } from "../utils/Strings";
import { __ } from "../utils/translation";
import {absolutePublicUrl, designFileUrl, publicUrl} from "../utils/url";
import { Design } from "./Design";

/**
 * @typedef {{id:Number, requiredMaterial:Array, code:String, title:String}} FinishData
 * @typedef {{id:Number, requiredMaterial:Array, title:String}} AddonData
 * @typedef {{id:Number, material:String, title:String, image:String}} MatData
 * @typedef {{fov:Number, product_id:Number, name:String, sizes:Array<String>, size:String, type:String}} Config
 */

export default class ProjectClass{
    @observable id;
    @observable hash;
    @observable is_added_to_cart;
    @observable is_project_bought;
    @observable size;
    @observable templateSize;
    @observable product_id;
    @observable material;
    @observable finishing;
    // @observable addons = [];
    @observable designs:Design[] = [];
    @observable store:StoreClass;
    @observable pendingRequest = false;
    @observable errorMessage = '';

    @computed get totalQuantity(){
        return this.designs.filter(d=>d.quantity).reduce((sum, d) => d.quantity + sum,0);
    }

    @computed get totalPrice(){
        return this.designs.filter(d=>d.totalPrice).reduce((sum, d) => d.totalPrice + sum,0) + this.totalBags;
    }
    @computed get totalBags(){
        // return this.designs.filter(d=>d.totalBags).reduce((sum, d) => d.totalBags + sum,0);

        if(this.totalQuantity > 100000){
            // var biggestQuantityDesign = this.designs[0];
            // this.designs.forEach(d=>{
            //     if(d.quantity > biggestQuantityDesign.quantity){
            //         biggestQuantityDesign = d;
            //     }
            // });
            if(this.designs[0]?.options?.length > 0){

                var options = this.designs[0].options;
                var option = options[options.length-1];
                var unitPrice = option.price / option.value; 
                var total = unitPrice * this.totalQuantity;
                return total;
            }
            return 0;
            
        }else if(this.designs.length > 0 && this.designs[0].options.length > 0 && this.totalQuantity > 0){
            var options = this.designs[0].options;
            var option = options.find(o => o.value >= this.totalQuantity );
            if(option){
                return option.price;
            }else{
                var option = options[options.length-1];
                if(option){
                    return option.price;
                }
            }
            return 0;
        }
        return 0
    }
    @computed get addonsSummary(){
        return this.selectedAddons.map( (a, i) => {
            const totalPrice = this.designs.reduce((sum, d) => {
                const addon = d.addons.find(aa=> aa.id === a.id);
                const totalPrice = addon?.totalPrice || 0;
                return sum + totalPrice
            }, 0);

            return Object.assign({}, a, {
                totalPrice: totalPrice,
                quantity: this.totalQuantity,
            })
        })
    }
    @computed get totalAddons(){
        return this.addonsSummary.reduce((sum, a) => sum + a.totalPrice, 0);
    }

  
    
    config:Config = {
        size:''
    };

    
    


    @observable selectedMaterialId = 1;
    @computed get selectedMaterial(): MatData {
        return this.materials.find(m => m.id === this.selectedMaterialId);
    }
    @observable selectedFinishId = 2;
    @observable selectedAddonsIds = [];

    @observable materials: MatData[] = [];
    @observable finishes: FinishData[] = [];
    @computed get selectedFinish(): FinishData {
        return this.finishes.find(m => m.id === this.selectedFinishId);
    }
    @observable addons: AddonData[] = [];
    @computed get selectedAddons() {
        return this.addons.filter(a => {
            return this.selectedAddonsIds.includes(a.id)
        })
    }
    @computed get validFinishes() {
        if (this.selectedMaterialId) {
            return this.finishes.filter(f => f.requiredMaterial.includes(this.selectedMaterialId));
        }
        return [];
    }
    @computed get validAddons() {
        if (this.selectedMaterialId) {
            return this.addons.filter(a => a.requiredMaterial.includes(this.selectedMaterialId));
        }
        return [];
    }
    constructor({store}){
        
        if(!store){
            throw new Error(`store is not defined`);
        }
        this.store = store;
        // this.id = uid();
        makeAutoObservable(this)
        this.init();
    }

    readConfiguration(name){
        try {
            var conf = require(`../configs/${name}.js`);
            return conf?.default;
            
        } catch (err) {
            console.error(err);
            this.store.showError(__('Can\'t load configuration'));
        }
        return null;
    }

    @action async loadConfiguration() {

        const product_id = parseInt(this.store.router.getSegment(1)) || null;
        const hash = this.store.router.getSegment(2);
        const current_size = this.store.router.getSegment(0);
        // console.log({hash, product_id, current_size});
        // console.log({current_size});
        // debugger;
        var config1 = this.readConfiguration(current_size);
        config1.product_id = product_id;
        this.config = config1;
        // console.log({config1});

        if(!this.config){
            return false;
        }
        this.templateSize = this.config.templateSize;
        this.product_id = product_id;
        
        if(hash){
            await this.loadByHash(hash);
        }else{
            this.size = this.config.size;
            await this.save();
        }


        // this.materials = config1.materials;
        this.config.finishes = [{
                id: 2,
                title: 'Matte',
                code: 'matte',
                requiredMaterial: [1, 2, 3]
            },
            {
                id: 1,
                title: 'Glossy',
                code: 'glossy',
                requiredMaterial: [1, 2, 3]
            },
        ];
        // this.addons = config1.addons;

        await this.loadShopData(this.config);
        var params = new URLSearchParams(window.location.search);
        this.store.sf = new SketchfabControllerClass({
            uid: params.get('sketchfabId') || config1.sketchfabId
        });

        this.sf = this.store.sf;

        await this.sf.init()
        // hide other sizes
        // this.sf.hideNodes(config1.hide);
        var nodesToHides = this.config.sizes.filter(size => size !== current_size);
        this.sf.hideNodes(nodesToHides);
        this.sf.hideNodes(nodesToHides.map(size => `Shadow-${size}`));
        this.sf.showNodes(this.config.sizes.filter(size => size === current_size).map(size => `Shadow-${size}`));
        // hide O_materials O_normal_maps
        this.sf.hideNode('O_materials');
        this.sf.hideNode('O_normal_maps');

        await this.sf.recenterCamera();
        if(this.config.cameraPos){
            await this.sf.setCameraLookAt([...this.config.cameraPos], [...this.config.cameraTarget]);
        }
        // delay(1000);
        // this.sf.zoom(-1);
        // set camera
        // await this.sf.setFov(this.config.fov);
        // show/hide addons
        // await this.sf.loadTextures([
        //     'http://localhost:3000'+publicUrl('/assets/textures/paper/Stand-up-pouches-paper-TN_V.png')
        // ]);
        // load normal maps
        await this.sf.loadTextures(config1.naormalMap.map(({id, name}) => {
            return {
                url: absolutePublicUrl(`assets/textures/${name}`),
                name: id
            }
        }));
        // load textures
        var promisesTextures = [];
        var p = this.sf.loadTextures(this.designs.filter(d => d.templateUrl).map(d => d.templateUrl));
        promisesTextures.push(p);
        var p = this.sf.loadTextures(this.designs.filter(d => d.templateTransparentUrl).map(d => d.templateTransparentUrl));
        promisesTextures.push(p);
        var p = this.sf.loadTextures(this.designs.filter(d => d.templateMetallictUrl).map(d => d.templateMetallictUrl));
        promisesTextures.push(p);
        var p = this.sf.loadTextures(this.designs.filter(d => d.templateComposableUrl).map(d => d.templateComposableUrl));
        promisesTextures.push(p);
        var p = this.sf.loadTextures(this.designs.filter(d => d.templateUrlFull).map(d => d.templateUrlFull));
        promisesTextures.push(p);

        await Promise.all(promisesTextures);
        

        // await this.switchToDesign(this.designs[0]?.id);

        // await this.sf.addTexture('http://localhost/create-react-app-mobx/api/public/baz.jpg','map');
        // await this.sf.getTextureList();
        
        // render textures
        await this.render();


        window.addEventListener('resize', this.onResize);

        await delay(500);


    }
    @computed get isNew(){
        return !this.hash
    }

    @action async save(){
        this.pendingRequest = true;
        try {
            var response = null;
            if(this.isNew){
                // this.hash = hash();
                response = await createProject(this.toJSON())   
            }else{
                response = await updateProject(this.toJSON())  
            }

            // console.log(response);
            if(response.data.success){
                this.fromJSON(response.data.project);
                
                const hash = this.hash;
                const size = this.size;
                if (hash && size) {
                    simpleRouter.replaceState({ hash: hash, size: size }, null, `/${size}/${this.product_id}/${hash}/?lang=${this.store.currentLangCode}`);
                }

            }else{
                this.store.showError(__('Can\'t create project'));
            }

            return response;

        } catch (err) {
            console.error(err);
        } finally {
            this.pendingRequest = false;
        }
    }

    debouncedSave = debounce(() => {
        return this.save();
    }, 200);

    @action async loadByHash(hash){
        try {
            
            const response = await loadProject(hash);
            if(response.data?.success){
                this.fromJSON(response.data.project);
                if(this.size !== this.config.size){
                    this.store.showError(__('404 Project not found'));

                }
            } else if(response.data?.code == 403) {
                if(response.data?.message)
                    this.store.showError(__(response.data.message));
                else
                    this.store.showError(__('Can\'t load configuration'));
                
            }
            
            this.pendingRequest = true;

        } catch (err) {
            if(err?.response?.data?.code == 404) {
                this.store.showError(__('404 Project not found'));
            }else{
                console.error(err);
                throw err;
            }
            
        }finally{
            
            this.pendingRequest = false;
        }
    }

    init(){
        // if(this.designs.length === 0){
        //     this.createEmptyDesign();
        // }
    }

    @action async createEmptyDesign(){
        try {
            const response = await saveDesign({
                hash: this.hash,
                // quantity: 1,
            });
            if(response.data?.success){
                this.designs.push(new Design({
                    project: this,
                    ...response.data.design
                }));
                this.switchToDesign(response.data.design.id);
            }
            return response;
        } catch (err) {
            console.error(err);
        }
    }
    
    @action async addDesign(){
        return this.createEmptyDesign();
    }
    /**
     * 
     */
    @action async removeDesign(d:Design){

        this.removing = true;

        try {
            const response = await deleteDesign(d.id);
            if(response.data?.success){
                this.designs = this.designs.filter(dd => dd.id !== d.id)
            }
            return response;
        } catch (err) {
            console.error(err);
        }

    }

    @computed get isProjectValid(){
        var isValid = true;

        if(this.designs.length === 0){
            isValid = false;
        }
        
        this.designs.forEach(d => {
            if(!d.report.hasSuccess || !d.hasUploadedFile){
                isValid = false;
            }
        });
        return isValid;
    }

    @action async selectMaterial(id) {
        this.selectedMaterialId = id;
        if (this.validFinishes.length === 0) {
            this.selectedFinishId = 0;
        } else if (this.validFinishes.length > 0 && !this.selectedFinishId) {
            this.selectedFinishId = this.validFinishes[0].id
        }

        this.addons.forEach(a => {
            const isValid = this.validAddons.find(aa => aa.id === a.id);
            if (!isValid && this.selectedAddonsIds.includes(a.id)) {
                this.toggleAddon(a);
            }
        });

        this.debouncedSave();

        await this.onMaterialOrFinishChange();
    }
    @action async selectFinish(id) {
        this.selectedFinishId = id;

        this.debouncedSave();

        await this.onMaterialOrFinishChange();
    }
    _hasAddon(id) {
        return this.selectedAddonsIds.includes(id);
    }

    @action async switchToDesign(id) {

        const design = this.designs.find(d => d.id === id);
        if (design) {
            this.currentDesignId = id;
            await design.reloadTextures();
        }
    }
    @observable currentDesignId;
    @computed get currentDesign(){
        return this.designs.find(d=>d.id === this.currentDesignId);
    };
    @computed get currentTextures() {
        var design = this.designs.find(d=>d.id === this.currentDesignId)
        if (design) {
            return design.textures;
        }
        return null
    }

    @action nextDesign(){
        var index = this.designs.findIndex(d=>d.id === this.currentDesignId);
        if(this.designs.length > index + 1){
            this.switchToDesign(this.designs[index + 1].id);
        }else{
            this.switchToDesign(this.designs[0].id);
        }
    }
    @action prevDesign(){
        var index = this.designs.findIndex(d=>d.id === this.currentDesignId);
        if(index > 0){
            this.switchToDesign(this.designs[index - 1].id);
        }else{
            this.switchToDesign(this.designs[this.designs.length - 1].id);
        }
    }

    getSourceMaterialByType() {
        const mId = this.selectedMaterialId;
        const fId = this.selectedFinishId;
        //    glossy
        if (fId === 1 && mId === 1) {
            return this.sf.findMaterialByName('M_metallic_plastic_glossy');
        }
        if (fId === 1 && mId === 2) {
            return this.sf.findMaterialByName('M_white_plastic_glossy');
        }
        if (fId === 1 && mId === 3) {
            return this.sf.findMaterialByName('M_transparent_plastic_glossy');
        }
        if (fId === 2 && mId === 1) {
            return this.sf.findMaterialByName('M_metallic_plastic_matte');
        }
        if (fId === 2 && mId === 2) {
            return this.sf.findMaterialByName('M_white_plastic_matte');
        }
        if (fId === 2 && mId === 3) {
            return this.sf.findMaterialByName('M_transparent_plastic_matte');
        }
        if (mId === 4) {
            return this.sf.findMaterialByName('M_compostable_brown_paper');
        }
        if (mId === 5) {
            return this.sf.findMaterialByName('M_paper');
        }
    }

    getNormalMapTexCoordUnit(){
        return this.config?.normalMaptexCoordUnit || 6;
    }

    getDefaultTexCoordUnit(){
        return this.config?.defaultCoordUnit || 1;
    }

    @action async render() {
        await this.updateAllAddons();
        return this.onMaterialOrFinishChange();
    }
    @action async onBackgroundChange(){
        var isLight = this.store.lightBgEnabled;
        var base_material_name = 'M_shadow';
        const mat = this.sf.findMaterialByName(base_material_name);
        if(!isLight){
            mat.channels.EmitColor.color = [46/255, 46/255, 46/255]; //gray
        }else{
            mat.channels.EmitColor.color = [0.5711248295, 0.1022417331, 0]; // yellow
        }

        this.sf.setMaterial(mat);
    }
    @action async onMaterialOrFinishChange() {

        const {
            code
        } = this.selectedFinish || {};
        const {
            material
        } = this.selectedMaterial || {};
        var base_material_name = 'M_main';
        const mat = this.sf.findMaterialByName(base_material_name);
        const cbp = this.sf.findMaterialByName('M_compostable_brown_paper');
        const base_white = this.sf.findTextureByName('T_white.png');
        if(!mat){
            throw new Error(`Material ${base_material_name} not found`);
        }
        // const mat2 = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-clear');
        // const mat2 = this.sf.findMaterialByName('M_Stand-up-pouches-paper-TN_V');
        // const mat2 = this.sf.findMaterialByName('M_Stand-up-pouches-paper-V');
        var name = material;
        if (this.validFinishes.length && code) {
            name += '_' + code;
        }
        const hasTN = this._hasAddon(2);
        const hasV = this._hasAddon(5);

        // const mat2 = this.sf.findMaterialByName(name);
        // copy normal map from this material

        // if (!mat2) {
        //     throw new Error(`Material ${name} not found`)
        // } else {
        //     console.log(`material changed to ${name}`)
        // }
        // copy chanell details from this material
        const source_mat = this.getSourceMaterialByType();
        Object.keys(mat.channels).forEach(name => {
            // mat.channels[name] = mat2.channels[name];
            if(source_mat){
                mat.channels[name] = source_mat.channels[name];
            }
            if (['NormalMap', 'ClearCoat', 'ClearCoatNormalMap', 'ClearCoatRoughness'].includes(name) && normal_map) {
                // debugger
                mat.channels[name] = normal_map.channels[name];
            }
        });

        var normal_map;
        if (hasV && hasTN) {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-TN_V');
            normal_map = this.sf.getLoadedTextureUid('TN_V');
        } else if (hasV) {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-V');
            normal_map = this.sf.getLoadedTextureUid('V');
        } else if (hasTN) {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-TN');
            normal_map = this.sf.getLoadedTextureUid('TN');
        } else {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-clear');
            normal_map = this.sf.getLoadedTextureUid('clear');

        }
        // var nmuid = this.sf.getLoadedTextureUid('Standup-pouch-plastic-TN_V_NRM.jpg');
        if(normal_map){
            mat.channels.NormalMap.texture.uid = normal_map;
            mat.channels.NormalMap.texture.texCoordUnit = this.getNormalMapTexCoordUnit();
        }

        ['AlbedoPBR', 'Opacity', 'MetalnessPBR'].map( chName => {
            if(mat.channels[chName]?.texture){
                mat.channels[chName].texture.texCoordUnit = this.getDefaultTexCoordUnit();
            }
        });
        
        if (this.currentTextures) {
            const uid = this.sf.getLoadedTextureUid(this.currentTextures.mainTexture);
            const uid_t = this.sf.getLoadedTextureUid(this.currentTextures.opacityTexture);
            const uid_m = this.sf.getLoadedTextureUid(this.currentTextures.metalnessTexture);
            if(mat.channels.AlbedoPBR.texture?.textureTarget){
                mat.channels.AlbedoPBR.texture.uid = uid;
            }else{
                mat.channels.AlbedoPBR.texture = uid;
            }
            // mat.channels.AlbedoPBR.color = false;
            // mat.channels.Matcap.texture = uid;
            // debugger;
            // mat.channels.Opacity.type = "luminanceBlend"; 
            // mat.channels.Opacity.texture = uid_t;
            if(uid_t){

                mat.channels.Opacity.texture = {
                    internalFormat: "LUMINANCE",
                    magFilter: "LINEAR",
                    minFilter: "LINEAR_MIPMAP_LINEAR",
                    texCoordUnit: this.getDefaultTexCoordUnit(),
                    textureTarget: "TEXTURE_2D",
                    uid: uid_t,
                    wrapS: "REPEAT",
                    wrapT: "REPEAT"
                };
            }
            if(uid_m){

                mat.channels.MetalnessPBR.texture = {
                    internalFormat: "LUMINANCE",
                    magFilter: "LINEAR",
                    minFilter: "LINEAR_MIPMAP_LINEAR",
                    texCoordUnit: this.getDefaultTexCoordUnit(),
                    textureTarget: "TEXTURE_2D",
                    uid: uid_m,
                    wrapS: "REPEAT",
                    wrapT: "REPEAT",
                };
            }
        }else{
            if(mat.channels.AlbedoPBR.texture?.textureTarget){
                mat.channels.AlbedoPBR.texture.uid = base_white.uid;
            }else{
                mat.channels.AlbedoPBR.texture = base_white.uid;
            }
            if(mat.channels.Opacity.texture){
                mat.channels.Opacity.texture.uid = null;
            }
            if(mat.channels.MetalnessPBR.texture){

                mat.channels.MetalnessPBR.texture.uid = null;
            }
            Object.keys(cbp.channels).forEach(k=>{
                mat.channels[k] = cbp.channels[k];
            });
            if(mat.channels.NormalMap.texture){
                mat.channels.NormalMap.texture.texCoordUnit = this.getNormalMapTexCoordUnit();
            }
        }

        // var map = this.sf.getLoadedTextureUid('map');
        // debugger
        // if(mat2){
        //     mat.channels.NormalMap.texture.uid = mat2.uid;
        //     mat.channels.AlbedoPBR.enable = true;
        // }

        // console.log({
        //     mat,
        //     // mat2,
        //     hasTN,
        //     hasV,
        //     normal_map,
        //     source_mat
        // });

        this.sf.setMaterial(mat);

    }

    @action renderNormalMapTCU(number, number2 = 0){


        const {
            code
        } = this.selectedFinish || {};
        const {
            material
        } = this.selectedMaterial || {};
        var base_material_name = 'M_main';
        const mat = this.sf.findMaterialByName(base_material_name);
        const cbp = this.sf.findMaterialByName('M_compostable_brown_paper');
        const base_white = this.sf.findTextureByName('T_white.png');
        if(!mat){
            throw new Error(`Material ${base_material_name} not found`);
        }
        // const mat2 = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-clear');
        // const mat2 = this.sf.findMaterialByName('M_Stand-up-pouches-paper-TN_V');
        // const mat2 = this.sf.findMaterialByName('M_Stand-up-pouches-paper-V');
        var name = material;
        if (this.validFinishes.length && code) {
            name += '_' + code;
        }
        const hasTN = this._hasAddon(2);
        const hasV = this._hasAddon(5);

        // const mat2 = this.sf.findMaterialByName(name);
        // copy normal map from this material

        // if (!mat2) {
        //     throw new Error(`Material ${name} not found`)
        // } else {
        //     console.log(`material changed to ${name}`)
        // }
        // copy chanell details from this material
        const source_mat = this.getSourceMaterialByType();
        Object.keys(mat.channels).forEach(name => {
            // mat.channels[name] = mat2.channels[name];
            if(source_mat){
                mat.channels[name] = source_mat.channels[name];
            }
            if (['NormalMap', 'ClearCoat', 'ClearCoatNormalMap', 'ClearCoatRoughness'].includes(name) && normal_map) {
                // debugger
                mat.channels[name] = normal_map.channels[name];
            }
        });

        var normal_map;
        if (hasV && hasTN) {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-TN_V');
            normal_map = this.sf.getLoadedTextureUid('TN_V');
        } else if (hasV) {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-V');
            normal_map = this.sf.getLoadedTextureUid('V');
        } else if (hasTN) {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-TN');
            normal_map = this.sf.getLoadedTextureUid('TN');
        } else {
            // normal_map = this.sf.findMaterialByName('M_Stand-up-pouches-plastic-clear');
            normal_map = this.sf.getLoadedTextureUid('clear');

        }
        // var nmuid = this.sf.getLoadedTextureUid('Standup-pouch-plastic-TN_V_NRM.jpg');
        if(normal_map){
            mat.channels.NormalMap.texture.uid = normal_map;
            mat.channels.NormalMap.texture.texCoordUnit = number;
        }

        ['AlbedoPBR', 'Opacity', 'MetalnessPBR'].map( chName => {
            if(mat.channels[chName]?.texture){
                mat.channels[chName].texture.texCoordUnit = number2;
            }
        });
        
        if (this.currentTextures) {
            const uid = this.sf.getLoadedTextureUid(this.currentTextures.mainTexture);
            const uid_t = this.sf.getLoadedTextureUid(this.currentTextures.opacityTexture);
            const uid_m = this.sf.getLoadedTextureUid(this.currentTextures.metalnessTexture);
            if(mat.channels.AlbedoPBR.texture?.textureTarget){
                mat.channels.AlbedoPBR.texture.uid = uid;
            }else{
                mat.channels.AlbedoPBR.texture = uid;
            }
            // mat.channels.AlbedoPBR.color = false;
            // mat.channels.Matcap.texture = uid;
            // debugger;
            // mat.channels.Opacity.type = "luminanceBlend"; 
            // mat.channels.Opacity.texture = uid_t;
            if(uid_t){

                mat.channels.Opacity.texture = {
                    internalFormat: "LUMINANCE",
                    magFilter: "LINEAR",
                    minFilter: "LINEAR_MIPMAP_LINEAR",
                    texCoordUnit: number2,
                    textureTarget: "TEXTURE_2D",
                    uid: uid_t,
                    wrapS: "REPEAT",
                    wrapT: "REPEAT"
                };
            }
            if(uid_m){

                mat.channels.MetalnessPBR.texture = {
                    internalFormat: "LUMINANCE",
                    magFilter: "LINEAR",
                    minFilter: "LINEAR_MIPMAP_LINEAR",
                    texCoordUnit: number2,
                    textureTarget: "TEXTURE_2D",
                    uid: uid_m,
                    wrapS: "REPEAT",
                    wrapT: "REPEAT",
                };
            }
        }else{
            if(mat.channels.AlbedoPBR.texture?.textureTarget){
                mat.channels.AlbedoPBR.texture.uid = base_white.uid;
            }else{
                mat.channels.AlbedoPBR.texture = base_white.uid;
            }
            if(mat.channels.Opacity.texture){
                mat.channels.Opacity.texture.uid = null;
            }
            if(mat.channels.MetalnessPBR.texture){

                mat.channels.MetalnessPBR.texture.uid = null;
            }
            Object.keys(cbp.channels).forEach(k=>{
                mat.channels[k] = cbp.channels[k];
            });
            if(mat.channels.NormalMap.texture){
                mat.channels.NormalMap.texture.texCoordUnit = number;
            }
        }

        // var map = this.sf.getLoadedTextureUid('map');
        // debugger
        // if(mat2){
        //     mat.channels.NormalMap.texture.uid = mat2.uid;
        //     mat.channels.AlbedoPBR.enable = true;
        // }

        // console.log({
        //     mat,
        //     // mat2,
        //     hasTN,
        //     hasV,
        //     normal_map,
        //     source_mat
        // });

        this.sf.setMaterial(mat);

    
    }
    @action async loadShopData(config){
        if(config.product_id){
            try {
                const response = await getProduct(config.product_id);
                // console.log(response.data);
                
                this.updateConfig(response.data, config);
                
                
            } catch (err) {
                console.error(err);
                this.store.showError(err.response?.data?.message);
            }
        }else{
            this.updateConfig({}, config);
        }
    }

    updateConfig(data, config){
        const { addons = [], finish = [], materials = [], pricing_tables = {}, id, lang = 'en' } = data;
        this.store.currentCurrency = lang === 'en'? 'EUR' : 'DKK';
        this.store.initCurrency();
        
        const findBySlug = (slug, arr) => {
            return arr.find(e => e.slug === slug);
        }
        const mapPricingTable = (arr) => {
            return (arr||[]).map( v =>{
                return Object.assign({},v,{
                    value: parseInt(v.quantity) || 0,
                    label: `${v.quantity} ${__('pieces')}`,
                    price: parseFloat(v.price) || 0,
                    price_label: formatCurrency(v.price),
                    pack: `${formatCurrency(v.price/v.quantity)} / ${__('pieces')}`
                });
            })
        }

        this.addons = config.addons.map( a => {
            var price = 0;
            var shop_id = 0;
            var obj;
            switch (a.id) {
                case 1:
                    obj = findBySlug('corner-rounding', addons);
                    break;
                case 2: // zip lock
                    obj = findBySlug('zipper', addons);
                    break;
                case 3: // tear noth
                    obj = findBySlug('tear-notch', addons);
                    break;
                case 4: // euro hole
                    obj = findBySlug('euro-hole', addons);
                    break;
                case 5: // valve
                    obj = findBySlug('valve', addons);
                    break;
                
            }
            if(obj){
                price = parseFloat(obj.price) || 0;
                shop_id = obj.id;
            }
            return Object.assign({}, a, {
                price, shop_id
            });
        });
        this.finishes = config.finishes.map( a => {
            var price = 0;
            var shop_id = 0;
            var obj;
            switch (a.id) {
                case 1: //glossy
                    obj = findBySlug('glossy', finish);
                    break;
                case 2: // matt
                    obj = findBySlug('matte', finish);
                    break;
                
            }
            if(obj){
                price = parseFloat(obj.price) || 0;
                shop_id = obj.id;
            }
            return Object.assign({}, a, {
                price, shop_id
            });
        });
        this.materials = config.materials.map( (a,i) => {
            var price = 0;
            var shop_id = 0;
            var quantity = [];
            var obj;
            switch (a.id) {
                case 1: //metallic-plastic
                    obj = findBySlug('metallic-plastic', materials);
                    quantity = mapPricingTable(pricing_tables["metallic-plastic"]);
                    break;
                case 2: // transparent-plastic
                    obj = findBySlug('white-plastic', materials);
                    quantity = mapPricingTable(pricing_tables["white-plastic"]);
                    break;
                case 3: // transparent-plastic
                    obj = findBySlug('transparent-plastic', materials);
                    quantity =  mapPricingTable(pricing_tables["transparent-plastic"]);
                    break;
                case 4: // compostable-brown-paper
                    obj = findBySlug('compostable-brown-paper', materials);
                    quantity = mapPricingTable(pricing_tables["compostable-brown-paper"]);
                    break;
                case 5: // paper
                    obj = findBySlug('paper', materials);
                    quantity = mapPricingTable(pricing_tables.paper);
                    break;
                
            }
            if(obj){
                price = parseFloat(obj.price) || 0;
                shop_id = obj.id;
            }
            return Object.assign({}, a, {
                price, shop_id, quantity,
                code:`mat${i+1}_title`
            });
        });


    }

    @action toggleAddon(addon: AddonData) {
        if (this.selectedAddonsIds.includes(addon.id)) {
            this.selectedAddonsIds = this.selectedAddonsIds.filter(a => a != addon.id);
        } else {
            this.selectedAddonsIds.push(addon.id)
        }

        this.addons.forEach(a => {
            const isSelected = this.selectedAddonsIds.includes(a.id); 
            const replaceSizeTag = arr => arr.map(name=>name.replace('{size}', this.config.size));
            
            if (isSelected) {
                var {
                    show,
                    hide
                } = a;
            } else {
                var {
                    show: hide,
                    hide: show
                } = a;
            }
            if (hide?.length) {
                this.sf.hideNodes(replaceSizeTag(hide))
            }
            if (show?.length) {
                this.sf.showNodes(replaceSizeTag(show))
            }
        });

        this.debouncedSave();

        this.onMaterialOrFinishChange();

    }
    @action updateAllAddons() {


        this.addons.forEach(a => {

            const isSelected = this.selectedAddonsIds.includes(a.id); 
            const replaceSizeTag = arr => arr.map(name=>name.replace('{size}', this.config.size));
            
            if (isSelected) {
                var {
                    show,
                    hide
                } = a;
            } else {
                var {
                    show: hide,
                    hide: show
                } = a;
            }
            if (hide?.length) {
                this.sf.hideNodes(replaceSizeTag(hide))
            }
            if (show?.length) {
                this.sf.showNodes(replaceSizeTag(show))
            }
        })


    }

    toJSON(){
        return {
            id: this.id,
            hash: this.hash,
            size: this.size,
            product_id: this.product_id,
            config:{
                // hash: this.hash,
                material_id: toJS(this.selectedMaterialId),
                addons: toJS(this.selectedAddonsIds),
                finish_id: this.selectedFinishId,
                shop_ids: {
                    config: this.configToShopIds()
                }
            },
            
        }
    }
    
    configToShopIds() {
        let addons = [];
        this.selectedAddons.forEach(addon => {
            addons.push(addon.shop_id)
        });

        return {
                material_id: this.selectedMaterial?.shop_id || null,
                addons: toJS(addons),
                finish_id: this.selectedFinish?.shop_id || null
        }
    }
    @action fromJSON(json){
        // console.log('fromJSON', json);
        this.id = json.id;
        this.size = json.size;
        this.product_id = parseInt(json.product_id);
        this.hash = json.hash;
        this.is_project_bought = json.is_project_bought;
        this.is_added_to_cart = json.is_added_to_cart;
        // debugger;
        const { material_id, finish_id, addons } = json.config;
        if(material_id){
            this.selectedMaterialId = material_id;
        }
        if(finish_id){
            this.selectedFinishId = finish_id;
        }
        if(addons?.length > 0){
            this.selectedAddonsIds = addons;
        }

        if(json.designs?.length){
            this.designs = json.designs.map( d => new Design({
                project: this, ...d
            }));
            this.currentDesignId = this.designs[0].id;
        }
        
    }

    toShopJSON(){
        let products = [];

        this.designs.forEach(design => {
            products.push({
                product_id: this.config.product_id,
                hash: this.hash, 
                configuratorUrl: absolutePublicUrl(this.size+"/"+this.hash),
                qty: design.quantity,
                size: this.size,
                config: this.configToShopIds(),
                design: {
                    name: design.name,
                    url: designFileUrl(design.id, design.file_path),
                }
            })
        })
        return products;
    }
    
    @action async saveDraft(){
        try {
            const response = await this.save();
            if(response.data.success) {
                try {
                    const response = await saveAsDraftShop(this.toJSON());
                    // if (response.data?.success) {
                        
                    // }
                    if (response.status === 200 && response.data?.url) {
                        window.location.href = response.data.url;
                    }
                    return response;
                } catch (err) {
                    console.error(err);
                    if(err?.response?.data?.message){
                        this.store.showError(err.response.data.message);
                    }
                }
            }
        } catch (err) {
            console.error(err)
        }
    }
    
    @action async addToCart() {
        try {
            const response = await this.save();
            if(response.data.success) {
                try {
                    const response = await addToCartShop(this.toJSON());
                    if (response.status === 200 && response.data?.url) {
                        window.location.href = response.data.url;
                    }
                    return response;
                } catch (err) {
                    console.error(err);
                    if(err?.response?.data?.message){
                        this.store.showError(err.response.data.message);
                    }
                }
            }
        } catch (err) {
            console.error(err)
        }
        
    }
}