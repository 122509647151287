import { computed, makeAutoObservable, observable } from "mobx";
import ProjectClass from "../models/ProjectClass";
import { createAr } from "./api";

const QRCode = require('qrcode')


// paper, metallic plastic, transparent plastic, white plastic, compostable brown paper
function getMaterial(materialId){
    switch (materialId) {
        case 1:
            return 'metallic plastic';
            break;
        case 2:
            return 'white plastic';
            break;
        case 3:
            return 'transparent plastic';
            break;
        case 4:
            return 'compostable brown paper';
            break;
        case 5:
            return 'paper';
            break;
    
        default:
            return 'metallic plastic';
            break;
    }
}

    
export function prepareButton(el){
    /** @type {Element} */
    // var el = document.querySelector(selector);
    if(el){
        var file_url_glb = el.getAttribute('data-glb-url');
        var file_url_usdz = el.getAttribute('data-usdz-url');
        var vertical = 'true';
        var href = `intent://arvr.google.com/scene-viewer/1.0?file=${file_url_glb}&mode=ar_only&enable_vertical_placement=${vertical}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            href = `${file_url_usdz}#allowsContentScaling=0`;
        }
        el.href = href;
    }
}





/**
 * "matte, glossy",
 * @param {Number} finishId 
 * @returns {String} "matte, glossy",
 */
function getFinish(finishId){
    switch (finishId) {
        case 1:
            return 'glossy';
            break;
        case 2:
            return 'matte';
            break;
        case 3:
    
        default:
            return 'matte';
            break;
    }
}

class ArManagerClass{
    project: ProjectClass
    @observable response;
    @observable pendingRequest = false;

    @computed get arModelUrl(){
        if(this.response?.url){
            return `${this.response?.url}&lang=${this.project.store.currentLangCode}`;
        }
        return null;
    }
    constructor({project}){
        this.project = project;

        makeAutoObservable(this);

        if(!project){
            throw new Error('project is udefined');
        }
    }

    prepareButtons(){
        document.querySelectorAll("[data-glb-url]").forEach((el, i) =>{
            prepareButton(el);
        })
    }

    getSettingsObject(){
        var obj = {
            // "_type": "Standup-pouch, Side-gussets",
            "type" : "Standup-pouch",
        
            // "_size": "120x200x80, 160x230x80, 190x260x110, 200x305x80, 250x300x100",
            "size" : "120x200x80",
        
        
            // "_material": "paper, metallic plastic, transparent plastic, white plastic, compostable brown paper",
            "material" : "white plastic",
        
            // "_material_type": "matte, glossy",
            "material_type" : "glossy",
        
        
            "euro_hole"      : false,
            "rounded_corners": false,
            "tear_notch"     : false,
            
            "zipper"         : false,
            "valve"          : false
        };

        obj.type = this.project.config.type;
        obj.size = this.project.config.size;
        obj.material = getMaterial(this.project.selectedMaterialId);
        obj.material_type = getFinish(this.project.selectedFinishId);
        // rounded_corners 1
        // zipper 2
        // tear_notch 3
        // euro_hole 4
        // valve 5
        obj.rounded_corners = this.project._hasAddon(1);
        obj.zipper = this.project._hasAddon(2);
        obj.tear_notch = this.project._hasAddon(3);
        obj.euro_hole = this.project._hasAddon(4);
        obj.valve = this.project._hasAddon(5);

        obj.textures = this.project.currentTextures;
    
        return obj;
    }

    getJSON(){
        return JSON.stringify(this.getSettingsObject());
    }


    async createAr(){
        this.response = null;
        try {
            const response = await createAr(this.getJSON());
            this.response = response.data;
            if(response.data?.success){

            }
            return this.response;
        } catch (err) {
            console.error(err);
            
        } finally{
            this.pendingRequest = false;
        }
    }
}

export default ArManagerClass;

