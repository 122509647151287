function toRadians(degrees) {
    var pi = Math.PI
    return degrees * (pi / 180)
}

var sf;
export default class SketchfabControllerClass {
    sf
    nodes = [];
    materials = [];
    textures = [];
    loadedTextures = {};
    graph;
    uid
    constructor({uid}) {
        this.uid = uid
    }

    async init() {
        return new Promise((resolve, reject) => {
            var iframe = document.getElementById('api-frame');
            var uid = this.uid;

            // By default, the latest version of the viewer API will be used.
            var client = new Sketchfab(iframe);
            var self = this;


            // Alternatively, you can request a specific version.
            // var client = new Sketchfab( '1.12.1', iframe );

            client.init(uid, {
                ui_help: 0,
                ui_hint: 0,
                transparent: 0,
                success: function onSuccess(api) {
                    api.start();
                    api.addEventListener('viewerready', async function () {
                        this.sf = api;
                        sf = api;
                        self.sf = api;

                        self.nodes = await self.getNodesMap();
                        self.graph = await self.getSceneGraph();
                        self.materials = await self.getMaterialList();
                        self.textures = await self.getTextureList();
                        self.annotations = await self.getAnnotations();

                        // API is ready to use
                        // Insert your code here
                        console.log('Viewer is ready');
                        resolve(this)

                    });
                },
                error: function onError(err) {
                    console.error('Viewer error',err);

                }
            });
        });
    }

 


    _findNodeID(name){
        return this.nodes.find(n => String(n.name) === name)?.instanceID;
    }
    findNode(name){
        return this.nodes.find(n => String(n.name) === name);
    }
    focusOnVisibleGeometries(){

        sf.focusOnVisibleGeometries(function(err) {
            if (!err) {
                // window.console.log('Camera recentered');
            }
        });
    }
    recenterCamera(){
        return new Promise((resolve, reject) => {
            
            sf.setEnableCameraConstraints(false, {
                preventFocus: true
              }, function (err) {
                if (err) { reject(err) };
                sf.focusOnVisibleGeometries(function (err2) {
                  if (err2) { reject(err2) };
                  sf.setEnableCameraConstraints(true, {
                    preventFocus: true
                  }, function (err3) {
                    if (err3) {
                        reject(err3);
                    }else{
                        resolve();
                    }
                  });
                });
              });
        });
        // sf.recenterCamera(function(err) {
        //     if (!err) {
        //         window.console.log('Camera recentered');
        //     }
        // });
    }

    
    setCameraLookAt(cameraPos, targetPos, duration = 0.5){
        return new Promise((resolve, reject) => {            
            sf.setCameraLookAt(cameraPos, targetPos, 4.3, function(err) {
                if (!err) {
                    // window.console.log('Camera moved');
                    resolve();
                }
            });
        });
    }

    zoom( factor, duration = 1, minRadius = 1, maxRadius = Infinity ) {
        return new Promise((resolve, reject) => {
            sf.getCameraLookAt( function( err, camera ) {
                if ( !err ) {
                    var currentPos = camera.position,
                        x = currentPos[ 0 ],
                        y = currentPos[ 1 ],
                        z = currentPos[ 2 ],
        
                        target = camera.target,
                        
                        rho = Math.sqrt( ( x * x ) + ( y * y ) + ( z * z ) ),
                        phi,
                        theta;
        
                    if ( isNaN( minRadius ) ) {
                        minRadius = 0.1;
                    }
        
                    if ( isNaN( maxRadius ) ) {
                        maxRadius = Infinity;
                    }
        
                    if ( rho === minRadius || rho === maxRadius ) {
                        return;
                    }
        
                    rho = ( rho * factor );
        
                    if ( rho < minRadius && factor < 0 ) {
                        rho = minRadius;
                    }
        
                    else if ( rho > maxRadius && factor > 0 ) {
                        rho = maxRadius;
                    }
        
                    phi = Math.atan2( y, x );
                    theta = Math.atan2( ( Math.sqrt( ( x * x ) + ( y * y ) ) ), z );
        
                    x = ( rho * Math.sin( theta ) * Math.cos( phi ) );
                    y = ( rho * Math.sin( theta ) * Math.sin( phi ) );
                    z = ( rho * Math.cos( theta ) );
        
                    sf.setCameraLookAt([ x, y, z ], target, duration, function(err) {
                        if (!err) {
                            // window.console.log('Camera moved');
                            resolve();
                        }else{
                            reject(err);
                        }
                    });
                }
            });
        });
    }

    gotoAnnotation(name) {
        sf.gotoAnnotation(sf.annotationsNamed[name], {
            preventCameraAnimation: true
        })
    }
    getAnnotations() {
        return new Promise((resolve, reject) => {
            sf.getAnnotationList( (p, list) => {
                resolve(list);
            });
        });
    }
    getLoadedTextureUid(name){
        return this.loadedTextures[name];
    }
    /**
     * 
     * @param {String} URL 
     * @returns 
     */
    async addTexture(url, name = null){
        return new Promise((resolve, reject) => {
            sf.addTexture(url, (err, textureUid) => {
                if (!err) {
                    // window.console.log('Texture loaded '+ url);
                    if(name){
                        this.loadedTextures[name] = textureUid;
                    }else{
                        this.loadedTextures[url] = textureUid;
                    }
                    resolve(textureUid);
                    
                }else{
                    console.error(err, url, name);
                    reject(err);
                }
            });
        });
    }
    /**
     * 
     * @returns 
     */
    async getTextureList(url){
        return new Promise((resolve, reject) => {
            sf.getTextureList((err, textures) =>{
                if (!err) {
                    this.textures = textures;
                    resolve(textures);
                }else{
                    reject(err);
                }
            })
        });
    }
    async loadTextures(urls){
        var promises = urls.map(url_or_object => {
            const { url, name } = url_or_object || {};
            if(url && name){
                return this.addTexture(url, name);
            }
            return this.addTexture(url_or_object);
        });
        return Promise.all(promises);
    }
    /**
     * 
     * @param {{color:Array, uid:String}} options 
     * @returns 
     */
    async setBackground(options){
        return new Promise((resolve, reject) => {
            sf.setBackground(options, function() {
                // window.console.log('Background changed');
                resolve(options);
            });
        });
    }
    async getNodesMap(){
        return new Promise((resolve, reject) => {
            sf.getNodeMap(function(err, nodes) {
                if (!err) {
                    var arr = Object.keys(nodes).map(k => nodes[k]);
                    resolve(arr)
                }else{
                    reject(err)
                }
            })
        });
    }
    async getMaterialList(){
        return new Promise((resolve, reject) => {
            sf.getMaterialList(function(err, materials) {
                if (!err) {
                    // this.materials = materials;
                    resolve(materials)
                }
            });
        });
    }
    async setMaterial(m){
        return new Promise((resolve, reject) => {
            sf.setMaterial(m, function() {
                resolve(m)
            });
        });
    }
    findMaterialByName(name){
        return this.materials.find(m => m.name === name)
    }
    findTextureByName(name){
        return this.textures.find(m => m.name === name)
    }
    async getSceneGraph(){
        return new Promise((resolve, reject) => {
            sf.getSceneGraph(function(err, graph) {
                if (!err) {
                    
                    resolve(graph)
                }else{
                    reject(err)
                }
            })
        });
    }

    async hideNode(name) {
        return new Promise((resolve, reject) => {
            try {
                var id = this._findNodeID(name);
                // console.log('hideNode', {name, id});
                sf.hide(id)
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }

    async hideNodes(list = []) {
        return new Promise((resolve, reject) => {
            try {
                list.forEach(name =>{
                    this.hideNode(name);
                })
                // console.log('hideNodes', {list});
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }

    async  showNode(name) {
        return new Promise((resolve, reject) => {
            try {
                var id = this._findNodeID(name);
                // console.log('showNode', {name, id});
                sf.show(id)
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }

    async  showNodes(list = []) {
        return new Promise((resolve, reject) => {
            try {
                list.forEach(name => {
                    this.showNode(name)
                })
                // console.log('showNodes', {list});
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }

    async  setFov(angle) {
        return new Promise((resolve, reject) => {
            try {
                sf.setFov(angle, function (err, angle) {
                    if (!err) {
                        // window.console.log('FOV set to', angle) // 45
                        resolve(angle)
                    } else {
                        console.error(err)
                    }
                })
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }


    async  setEnvironment({
        rotation = 0,
        enabled = true,
        uid = undefined
    }) {
        const _default = {
            enabled: true,
            exposure: 1.9199,
            lightIntensity: 0.4,
            rotation: 5.672320069,
            shadowEnabled: true
        }

        var options = Object.assign({}, _default, {
            rotation: rotation,
            enabled: enabled,
            uid
        })
        return new Promise((resolve, reject) => {
            try {
                sf.setEnvironment(options, function () {
                    // window.console.log('Environment changed')
                    resolve(true)
                })
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }



}