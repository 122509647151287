

const translation = {

    currency: 'EUR',
    btn_back: 'Back',
    ar_header: 'Scan the code and launch AR',
    ar_text: 'Point the camera on your smartphone to this code to see your pouch in AR',
    ar_close: 'Close',
    ar_loading: 'Your AR pouch is just moments away',
    
    "Standup-pouch":"Stand up pouch",
    "Side-gussets":"Side gussets",

    menu_pouch: 'Pouch builder',
    menu_products: 'Products',
    menu_markets: 'Markets',
    menu_design: 'Design services',
    menu_sustainability: 'Sustainability',
    menu_resources: 'Resources',
    menu_about: 'About',
    placeholder_quantity: 'Select the quantity',
    add_design: 'Add another design',
    table_total_quantity: 'Total order quantity',
    table_total_price: 'Total price',
    addons_title: 'Add-ons',
    finish_title: 'Finishing',
    material_title: 'Material',
    designs_title: 'Designs',
    total_quantity: 'Total quantity',
    "quantity": "Quantity",

    material_tooltip: 'Here you can choose the base material of your pouch',
    finishing_tooltip: 'Recyclable plastics can have matte or glossy finishing. You can choose which one you want to use here.',
    addon_tooltip: 'Here you can choose various add-ons for your pouch',

    heat_warning: 'Digital ink printed on paper is susceptible to heat. Light colouring can occur on the sealing areas. We advise using lighter colours in sealing areas or expect slight discolouration there.',

    mat1_title: 'Recyclable metalic plastic',
    mat2_title: 'Recyclable white plastic',
    mat3_title: 'Recyclable transparent plastic',
    mat4_title: 'Compostable',
    mat5_title: 'Recyclable paper (plastic free)',

    addon1_title: 'Rounded corners',
    addon2_title: 'Zip lock',
    addon3_title: 'Tear Notch',
    addon4_title: 'Euro hole',
    addon5_title: 'Valve',
    
    finish2_title: 'Matte',
    finish1_title: 'Glossy',

    drop_file: 'Drop your file here or <span class="link">browse</span>',
    max_filesize: 'Max. File size',
    price: 'Price',
    piece: 'Pc',
    pieces: 'pcs',
    tab_features: 'FEATURES',
    tab_design: 'DESIGN & QUANTITY',
    btn_sample_kit: 'FREE SAMPLE KIT',
    btn_watch_tutorial: 'Watch a video tutorial',
    btn_watch_tutorial_mobile: 'Video tutorial',
    btn_save_draft: 'Save as draft',
    btn_add_to_cart: 'Add to cart',
    btn_update_cart: 'Update cart',
    help_link:'Need a design help?',
    btn_confirm_continue: 'Confirm & Continue',
    btn_view_ar: 'View in AR',
    btn_template_download: 'Design template download',
    back_to_type_selection: 'Change type or size',
    text_design: 'You can choose as many different artworks as you want within the same order',
    header_design_step: 'Upload <span class="marked">designs</span>  and select their quantities',
    header_features: 'Configure the <span class="marked">features</span> of your pouch',

    design: 'Design',
    unlimited: 'Unlimited <span class="marked-link">FREE</span> designs!',
    unlimited_desc: 'You can choose as many different artworks as you want within the same order',




    changing_template: 'Changing the material or add-ons requires removing the uploaded designs. Do you want to continue?',
    changing_template_btn_ok: 'Yes',
    changing_template_btn_no: 'No',

    // modal
    design_processing: 'Processing file...',
    design_error: 'Error. Please {link} and reupload the correct file.',
    design_uploaded: 'Design uploaded successful',
    view_report: 'view report',
    Download_report_pdf: 'Download report.pdf',
    btn_guideline_download: 'Artwork guidelines download',
    file_guideline_download: 'Artwork_guidelines_Lprint.pdf',
    modal_header_1: 'Results of the technical and visual preflight check',
    modal_header_failed: 'Please correct the design file',
    modal_header_success: 'Success!',
    modal_body_success: 'Our automated preflight check has successfully verified your design. Looks like we’re good to go and your order is ready to be printed!',
    modal_body_failed: 'The preflight process showed some errors in the design file you uploaded. Please correct the points outlined in the list below and reupload the file. After that, your file should be good to go!',

    understand: 'I understand',
    material_description: `
    <h3>Materials</h3>
    <p>Our comprehensive range of sustainable pouch materials gives you the aesthetic edge and functionality you need to get your packaging noticed. For added convenience choose a reclosable zipper, while rounded corners really can really catch your customer’s eye.</p>

    <h5>Metallic plastic</h5>
    <p>Metallic plastic is a mono material structure which can be fully recycled anywhere that has an LDPE recycling stream. It provides the highest level of protection from air, moisture and UV. Its distinctive metallic layer means that it’s great for companies looking to give their packaging a premium silver or gold effect. </p>
    
    <h5>White plastic</h5>
    <p>Recyclable structure with high moisture and aroma protection. Its simple structure makes it less expensive than conventional non-recyclable pouches.</p>
   
    <h5>Transparent plastic</h5>
    <p>A pouch with a transparent window can showcase the quality of the product packed inside. It also provides a high level of protection and is fully recyclable.</p>
    
    <h5>Recyclable paper</h5>
    <p>Plastic free recyclable pouches made from FSC certified paper. Pouches are made from renewable sources. This is as eco-friendly as it gets!</p>
    <p>&nbsp;</p>
    `,
    addon_description: `
    <h3>Add-ons</h3>
    <p>Our different add-ons can help you make your pouch more versatile and easy to use for your customers.</p>

    <h5>Zip-lock</h5>
    <p>Keep your products fresh for longer and safely packaged with a reclosable zip lock.</p>
    
    <h5>Tear notch</h5>
    <p>A tear notch makes it easier for customers to open horizontal form pouches. The tiny slit in the end seal guides the customer, and provides them more convenient and fuss free access to the product. Not only that, it also ensures that no important information is torn through.    </p>
   
    <h5>Rounded corners</h5>
    <p>Rounded corners give your pouch a smoother look and help them to stand out from the competition.    </p>
    
    <h5>Euro hole</h5>
    <p>If you are producing smaller pouches that need to be hung in store, then a euro hole is an essential add-on.    </p>
    
    <h5>Coffee valve</h5>
    <p>A coffee valve allows coffee gasses to be released to the environment, without fresh air entering the inside of the pouch. This way your coffee stays fresh longer and your pouch is protected from inflating.    </p>
    <p>&nbsp;</p>
    `,
    upload_size: 'It seems like you are tyring to upload a file that is too large for us. Our max file size is 200MB',
    invalid_file:'Invalid file type. Please upload PDF file.',

    bright_label: 'Bright',
    neutral_label: 'Neutral',
    builder: 'Pouch Builder',

    yes_i_do: 'Yes, I do',
    watch_video_description: `
    <div class="text-center">
        <p>&nbsp;</p>
        <h3 class="watch-video-modal-title">Would you like to see a <span class="yellow-text-background">video tutorial?</span></h3>
        <p>&nbsp;</p>
        <p>We understand that it might be your first time using our pouch builder tool and things might be unclear. We have created this tutorial video for to make your life easier and guide you on how to use the tool.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
    `,

}
window.translation = translation;
export default translation 

