import { observer } from "mobx-react-lite"
import { CancelIcon } from "../icons/CancelIcon"
import { DownloadIcon } from "../icons/DownloadIcon"
import { PdfIcon } from "../icons/Pdf"
import RetryIcon from "../icons/RetryIcon"
import { Design } from "../models/Design"
import { deleteDesignFile } from "../utils/api"

import { niceBytes } from "../utils/File"
import { __ } from "../utils/translation"
import './FileManager.scss'
import ProgressBar from "./ProgressBar"

/**
 * 
 * @param {{item:Design}} param0 
 */
const Comp = ({ item }) => {
    const hasError = item.report.hasErrors && item.hasUploadedFile;
    return <div className={`FileManager ${hasError ? 'error' : ''}`}>

        <div className="FileManager__icon">
            <PdfIcon />
        </div>
        <div className="FileManager__content-inner">
            <div className="FileManager__content">
                <div className="FileManager__title">{item.fileName}</div>
                <div className="FileManager__note">{niceBytes(item.fileSize)}</div>
            </div>
            <div className="FileManager__btn">
                <button onClick={async e => {
                    item.deleteFile();
                }} className="FileManager__btn-cancel">
                    {item.file && item.failedUpload && <RetryIcon onClick={e => {
                        item.updateFile(item.file);
                    }} />}
                    <CancelIcon />
                </button>
            </div>
            <ProgressBar progress={item.progress} />
        </div>
    </div>

    return
}

const FileManager = observer(Comp)
FileManager.displayName = 'FileManager'

export default FileManager