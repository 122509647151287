import React, { Component } from 'react';
import classNames from 'classnames'
import './Select2.scss';

class Select2 extends Component {

    node: HTMLDivElement;

    static defaultProps = {
        clearButton: false,
        options: [],
        disabled: false,
        isDisabled: false,
        blocked: false,
        name: null,
        className: null,
        displayPropertyName: 'label',
        renderDisplayed: null,
        renderPlaceholder: null,
        onChange: null,
        onClose: null,
        closeAfterSelection: true,
        isSelected: null,
    }

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isFocused: false,
            selectedIndex: 0,
            focusedIndex: 0,
        }
    }

    static getDerivedStateFromProps(props, state) {
        // Any time the current user changes,
        // Reset any parts of state that are tied to that user.
        // In this simple example, that's just the email.
        if (props['options'] && !state['isOpen'] && props['isSelected'] === false) {
            return {
                selectedIndex: -1,
                focusedIndex: -1
            };
        } else if (props['options'] && !state['isOpen']) {

            var index = props.options.findIndex(o => o.value === props.value) || 0;


            if (state.focusedIndex !== index) {
                return {
                    selectedIndex: index,
                    focusedIndex: index
                };
            }
        }
        return null;
    }
    componentDidMount() {

        document.addEventListener("click", this.handleClickOutside.bind(this), true);
    }

    handleClickOutside(e) {
        // console.log( e.target );
        if (this.node) {
            var contains = this.node.contains(e.target) || this.node === e.target;
            if (contains === false) {
                this.setState({
                    isOpen: false,
                    isFocused: false
                });
            }
        }
    }


    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }


    handleClick = (e) => {
        e.preventDefault();

        if (!this.props.disabled && !this.props.isDisabled) {

            var isOpen = !this.state.isOpen;
            if (isOpen && this.inputnode) {
                this.inputnode.focus();
            }
            this.setState({
                selectedIndex: this.state.focusedIndex,
                isOpen: isOpen,
                isFocused: isOpen,
            });
        }
    }

    handleKeyDown = (e) => {
        // if (this.props.onKeyDown) {
        //     this.props.onKeyDown(e);
        // }
        const { selectedIndex, focusedIndex } = this.state;


        if (this.isDisabled()) {
            return false;
        }

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                if (!e.shiftKey) {
                    if (!this.state.isOpen || (!isNaN(focusedIndex) && focusedIndex === this.props.options.length - 1)) {
                        var _focusedIndex = focusedIndex;
                    } else if (focusedIndex < this.props.options.length - 1) {
                        var _focusedIndex = focusedIndex + 1;
                    } else {
                        var _focusedIndex = 0;
                    }
                    this.setState({
                        isOpen: true,
                        focusedIndex: _focusedIndex,
                    });
                } else {

                    this.setState({
                        isOpen: false,
                    });
                    if (this.props.onKeyDown) {
                        this.props.onKeyDown(e);
                    }
                }
                // console.log('ArrowDown');
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (!e.shiftKey) {
                    // console.log('ArrowUp');
                    if (focusedIndex > 0) {
                        this.setState({
                            focusedIndex: focusedIndex - 1
                        })
                    }
                } else {
                    this.setState({
                        isOpen: false,
                    });
                    if (this.props.onKeyDown) {
                        this.props.onKeyDown(e);
                    }
                }
                break;
            case 'Tab':

                this.setState({
                    isOpen: false,
                    isFocused: false
                });
                break;
            case 'Enter':
                e.preventDefault();
                // console.log('ArrowUp');
                var option = this.props.options.find((o, index) => index === focusedIndex);
                if (option) {
                    this.handleOptionClick(e, { option: option });
                }
                this.setState({
                    selectedIndex: focusedIndex,
                    isOpen: false,
                    isFocused: true
                });
                break;

            default:
                break;
        }
    }

    handleMouseOver = (e) => {
        if (this.props.onMouseOver) {
            this.props.onMouseOver(e);
        }
    }
    handleMouseOut = (e) => {
        if (this.props.onMouseOut) {
            this.props.onMouseOut(e);
        }
    }
    handleOptionOver = (e, { option, index }) => {

        this.setState({
            focusedIndex: index
        })
    }
    handleOptionOut = (e, { option, index }) => {

    }
    handleFocus = (e) => {
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
        var newState = {
            isFocused: true
        };

        if (!this.isDisabled()) {
            if (this.props.openOnFocus) {
                newState.isOpen = true;
            }
        }
        this.setState(newState);

    }
    handleBlur = (e) => {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
        this.setState({
            isFocused: false
        });
    }


    handleOptionClick = (e, { option }) => {
        e.preventDefault();

        if (!option || !option['disabled']) {
            if (this.props.onChange) {
                this.props.onChange(e, option);
            }
            if (this.props.closeAfterSelection) {
                this.close();
            }
        }
    }

    autoScroll = () => {
        if (this.node) {
            const option = this.node.querySelector(`.Select-option--focused,.Select2__option.focused`);
            if (option) {
                var scrollParent = option.closest('.Select2__dropdown');
                if (scrollParent.scrollTop + scrollParent.clientHeight < option.offsetTop + 50) {
                    scrollParent.scrollTop = option.offsetTop - scrollParent.clientHeight + 70;
                } else if (scrollParent.scrollTop > option.offsetTop) {
                    scrollParent.scrollTop = option.offsetTop;
                }
                // console.log({ option });
            }
        }
    };

    componentDidUpdate(prevProps, prevState) {
        this.autoScroll();
    }

    close = () => {
        this.setState({
            isOpen: false
        });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }


    renderOptions() {
        const { clearButton, isSelected = null } = this.props;
        return this.props.options.map((o, index) => {

            var _itemSelected = index === this.state.selectedIndex;
            if (isSelected === false) {
                _itemSelected = false;
            }

            if (this.props.components?.Option) {
                return <this.props.components.Option
                    key={index}
                    data={o}
                    innerProps={{
                        onClick: e => this.handleOptionClick(e, { option: o, index: index }),
                        onMouseOver: e => this.handleOptionOver(e, { option: o, index: index }),
                        onMouseOut: e => this.handleOptionOut(e, { option: o, index: index }),
                    }}
                    // onClick={}
                    isFocused={index === this.state.focusedIndex}
                    isDisabled={o.disabled}
                    isSelected={_itemSelected}
                    // innerRef
                    // innerProps
                    selectProps={this.props}
                >
                    <span>
                        {o.label}
                    </span>
                </this.props.components.Option>
            } else {
                return (
                    <div key={o.value} value={o.value}
                        onMouseOver={e => this.handleOptionOver(e, { option: o, index })}
                        onMouseOut={e => this.handleOptionOut(e, { option: o, index })}
                        className={classNames({
                            'Select2__option': true,
                            // 'active': o.value === this.props.value,
                            'active': _itemSelected,
                            'focused': index === this.state.focusedIndex,
                            'disabled': o.disabled,

                        })}>
                        <div onClick={e => this.handleOptionClick(e, { option: o })} className="Select2__option-label">
                            {this.props.renderOption ? this.props.renderOption(o) : o.label}
                        </div>
                        {clearButton && o.value === this.props.value && <button onClick={e => this.handleOptionClick(e, { option: null })} className="Select2__option-button">

                            <svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#cross"></use></svg>

                        </button>
                        }
                    </div>
                )
            }
        })
    }

    isDisabled() {
        var {
            disabled = false,
            isDisabled = false } = this.props;
        return Boolean(disabled || isDisabled);
    }

    render() {
        var {
            value = null,
            clearButton,
            disabled = false,
            isDisabled = false,
            blocked,
            options = [],
            className = null,
            name = null,
            description = null,
            footer = null,
            placeholder = null,
            renderDisplayed = null,
            renderPlaceholder = null,
            renderOption = null,
            displayPropertyName } = this.props;

        var isEmpty = !value;
        var displayed = '';
        // var selected = options.find
        var selected_option = options.find(o => o.value === value);

        if (selected_option && typeof renderDisplayed === 'function') {
            displayed = renderDisplayed(selected_option);
        } else if (!isEmpty && selected_option) {
            displayed = selected_option[displayPropertyName];
        }

        if (isEmpty && typeof renderPlaceholder === 'function') {
            placeholder = renderPlaceholder(this.props);
        }


        var isEmptyAndBlocked = isEmpty && blocked;
        if (isEmptyAndBlocked) {
            displayed = '';
            placeholder = '';
        }

        return (
            <div ref={el => this.node = el} className={`Select2 ${className} ` + classNames({
                'Select2': true,
                'Select2--focused': this.state.isFocused,
                'isOpen': this.state.isOpen,
                'isDisabled': this.isDisabled(),
                'isEmpty': isEmpty,
                'isEmptyAndBlocked': isEmptyAndBlocked,
            })}>
                <div
                    onClick={this.handleClick}
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    onKeyDown={this.handleKeyDown}

                    className="Select2__input-wrapper">
                    <input ref={el => this.inputnode = el}
                        data-group={this.props['data-group']}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        disabled={this.isDisabled()}
                        className="Select2__hidden-input" type="text" readOnly={true} value={displayed} name={name} />
                    <span disabled={disabled} className={classNames({
                        'Select2__input': true,
                        blocked: blocked,
                    })} type="text" value={displayed} readOnly>{displayed}</span>
                    <span disabled={disabled} className={classNames({
                        'Select2__placeholder': true,
                        blocked: blocked,
                    })} type="text" value={placeholder} readOnly>{placeholder}</span>
                    {/* <input disabled={disabled} className={classNames({
                        'Select2__placeholder': true,
                        blocked: blocked,
                    })} type="text" value={placeholder} readOnly /> */}
                    {
                        !isEmptyAndBlocked &&
                        <span className="Select2__arrow">
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                        </span>
                    }
                </div>
                <div className="Select2__dropdown shadow-default">
                    <div className="Select2__dropdown-wrapper">

                        {
                            description && <div className="Select2__description">{description}</div>
                        }
                        {
                            this.renderOptions()
                        }
                        {
                            footer && <div className="Select2__footer">{footer}</div>
                        }
                    </div>

                </div>


            </div>
        );
    }
}

export default Select2;
