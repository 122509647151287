

import React from 'react';
import './InlineWarning.scss';

const InlineWarning = ({ children }) => {
    return (
        <div className='InlineWarning'>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M9 0C11.3869 0 13.6761 0.948211 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948211 13.6761 0 11.3869 0 9C0 6.61305 0.948211 4.32387 2.63604 2.63604C4.32387 0.948211 6.61305 0 9 0ZM9 3.85714C8.83677 3.857 8.6753 3.89091 8.52591 3.9567C8.37652 4.02248 8.24249 4.11871 8.13238 4.23921C8.02228 4.35972 7.93852 4.50187 7.88645 4.65658C7.83439 4.81129 7.81516 4.97516 7.83 5.13771L8.29929 10.2883C8.31748 10.4614 8.39915 10.6217 8.52854 10.7383C8.65793 10.8548 8.82588 10.9192 9 10.9192C9.17412 10.9192 9.34207 10.8548 9.47146 10.7383C9.60085 10.6217 9.68252 10.4614 9.70071 10.2883L10.1687 5.13771C10.1835 4.97526 10.1644 4.81151 10.1124 4.65689C10.0604 4.50227 9.97673 4.36018 9.86676 4.23969C9.7568 4.1192 9.62293 4.02295 9.47369 3.95709C9.32445 3.89122 9.16313 3.85718 9 3.85714ZM9 14.1429C9.27279 14.1429 9.53441 14.0345 9.72731 13.8416C9.9202 13.6487 10.0286 13.3871 10.0286 13.1143C10.0286 12.8415 9.9202 12.5799 9.72731 12.387C9.53441 12.1941 9.27279 12.0857 9 12.0857C8.72721 12.0857 8.46558 12.1941 8.27269 12.387C8.0798 12.5799 7.97143 12.8415 7.97143 13.1143C7.97143 13.3871 8.0798 13.6487 8.27269 13.8416C8.46558 14.0345 8.72721 14.1429 9 14.1429Z" fill="#4BB8E8" />
            </svg>

            {children}
        </div>
    );
};

export default InlineWarning;