import { observer } from "mobx-react-lite"
import { CancelIcon } from "../icons/CancelIcon.jsx";
import ErrorIcon from "../icons/ErrorIcon.jsx";
import SuccessIcon from "../icons/SuccessIcon.jsx";
import { Design, EnfocusStatus } from "../models/Design";

import { __ } from "../utils/translation";
import DefaultDropzone from "./DefaultDropzone.jsx";
import { MEGABYTE } from "./Dropzone.jsx";
import FileManager from "./FileManager";
import Loader from "./Loader.jsx";
// import Dropzone from './Dropzone'
import Select2 from './Select2'

import './TextureUpload.scss'

import { swalAlert } from '../utils/Swal';

/**
 * 
 * @param {{item:Design}} param0 
 * @returns 
 */
const Comp = ({ item, onRemove, className = '', onPreview, active = false, index = 1 }) => {
    if (item.removing) {
        className += ` removing `;
    }
    if (active) {
        className += ` active `;
    }
    return <div className={`TextureUpload ${className}`} onClick={e => {
        onPreview(e, item);
    }}>
        <div onClick={onRemove} className="TextureUpload__remove"><CancelIcon /></div>
        <div className="TextureUpload__label h3">{index > 0 ? `${index}. ` : null}{__('design')}</div>
        {
            !item.hasFile &&
            <DefaultDropzone
                maxSize={MEGABYTE * 200}
                multiple={false}
                accept="application/pdf"
                onDrop={(file: File[], rejected: File[]) => {
                    // console.log({ e, a, r });
                    if (file.length) {
                        item.updateFile(file[0]);
                    }
                    if (rejected.length > 0) {
                        if (!String(rejected.at(0).name).match(/\.pdf$/i)) {
                            swalAlert({
                                type: 'warning',
                                title: 'Oops...',
                                text: __('invalid_file')
                            })
                        } else if (rejected.at(0).size > MEGABYTE * 200) {
                            swalAlert({
                                type: 'warning',
                                title: 'Oops...',
                                text: __('upload_size')
                            })
                        }
                    }
                }}
                name={'file'} />
        }
        {
            item.hasFile && <FileManager item={item} />
        }
        {
            item.enfocus_status === EnfocusStatus.ENFOCUS_UPLOADED_TO_FTP && <div className="error-bar">
                <div className="error-bar__icon">
                    <Loader visible={true} size={20} />
                </div>
                <div className="error-bar__text">
                    {__('design_processing')}
                </div>
            </div>
        }
        {
            item.report.hasErrors && item.hasUploadedFile && <div className="error-bar">
                <div className="error-bar__icon">
                    <ErrorIcon />
                </div>
                <div className="error-bar__text">
                    {__('design_error').split('{link}')[0]}
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        item.project.switchToDesign(item.id);
                        item.report.show();
                    }}>{__('view_report')}</a>
                    {__('design_error').split('{link}')[1]}
                    {' '}
                    <a href={item.project.store.helpLink} target={'_blank'} >{__('help_link')}</a>

                </div>
            </div>
        }
        {
            item.report.hasSuccess && item.hasUploadedFile && <div className="error-bar">
                <div className="error-bar__icon">
                    <SuccessIcon />
                </div>
                <div className="error-bar__text">
                    {__('design_uploaded')} <a href="#" onClick={e => {
                        e.preventDefault();
                        item.project.switchToDesign(item.id);
                        item.report.show();
                    }}>{__('view_report')}</a>


                </div>
            </div>
        }

        <div className="TextureUpload__label h3">{__('quantity')}</div>
        <Select2
            name={'quantity'}
            value={item.quantity}
            onChange={(e, option) => {
                item.updateQuantity(option.value);

            }}
            placeholder={__(`placeholder_quantity`)}
            renderOption={options => {
                return <div className="SizeOption__content">
                    <div className="SizeOption__name">{`${options.label}`}</div>
                    <div className="SizeOption__promo">{''}</div>
                    <div className="SizeOption__pack">{options.pack}</div>
                    <div className="SizeOption__price">{options.price_label}</div>
                </div>
            }}
            options={item.options}
        // footer={<div className="Select2__option  ">
        //     <div className="SizeOption__content">
        //         <div className="SizeOption__name">100000+ pcs.</div>
        //         <div className="SizeOption__promo">{''}</div>
        //         <div className="SizeOption__pack">
        //             <a target={'_blank'} href="https://lprint.stillnot.live/">Contact Us</a>
        //         </div>
        //         <div className="SizeOption__price"></div>
        //     </div>
        // </div>
        // }
        />
        <div className="TextureUpload__arrow"></div>
    </div >
}
const TextureUpload = observer(Comp);
TextureUpload.displayName = 'TextureUpload'

export default TextureUpload