import { DownloadIcon } from "../icons/DownloadIcon";
import { PdfIcon } from "../icons/Pdf";
import { __ } from "../utils/translation";

import './PdfDownload.scss'

export default function PdfDownload({ title, note, href }) {
    return <a href={href} className="PdfDownload" target={'_blank'} rel='noreferrer'>
        <div className="PdfDownload__icon">
            <PdfIcon />
        </div>
        <div className="PdfDownload__content">
            <div className="PdfDownload__title">{title}</div>
            <div className="PdfDownload__note">{note}</div>

        </div>
        <div className="PdfDownload__btn">
            <DownloadIcon /> <span className="PdfDownload__btn-label">{__(`Download`)}</span>
        </div>
    </a>
}