

export async function delay(ms){
    return new Promise((resolve, reject) => {
        setTimeout(()=>{
            resolve(ms);
        }, ms)
    });
}

export function debounce(callback, wait) {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

export function throttle(func, delay)  {
    let timeout = null;
  
    return function (...args) {
      if (timeout === null) {
        func.apply(this, args);
        
        timeout = setTimeout(() => {
          timeout = null;
        }, delay)
      }
    }
}