import { action, computed, makeAutoObservable, observable } from "mobx";
import { Design, EnfocusStatus } from "../models/Design";
import { __ } from "../utils/translation";
import { publicDir } from "../utils/url";

/**
 * @typedef {{error_messages:Array, fixes_messages:Array, warning_messages:Array}} EnfocusReport 
 */


export default class Report{
    @observable design:Design
    @observable data:Object<{en:EnfocusReport, dk:EnfocusReport}>

    @computed get image(){
        return publicDir(this.design?.project.selectedMaterial?.image);
    }
    @computed get title(){
        if(this.error_messages.length > 0){
            return __('modal_header_failed');
        }
        return __('modal_header_success');
    }
    @computed get body(){
        if(this.error_messages.length > 0){
            return __('modal_body_failed');
        }
        return __('modal_body_success');
    }
    // @observable templatePdfUrl = "#"
    @computed get pdfUrl(){

        const lang = this.design.project.store.currentLangCode;
        if(this.data && this.data[lang]){
            return this.data[lang].reportUrl;
        }
        return null;
    }

    @computed get error_messages(){
        const lang = this.design.project.store.currentLangCode;
        if(this.data && this.data[lang]?.error_messages){
            return this.data[lang].error_messages;
        }
        return [];
    };
    @computed get fixes_messages(){
        const lang = this.design.project.store.currentLangCode;
        if(this.data && this.data[lang]?.fixes_messages){
            return this.data[lang].fixes_messages;
        }
        return [];
    };
    @computed get warning_messages(){
        const lang = this.design.project.store.currentLangCode;
        if(this.data && this.data[lang]?.warning_messages){
            return this.data[lang].warning_messages;
        }
        return [];
    };

    @observable isLoading = false;
    @observable isOpen = false;


    @computed get hasErrors(){
        return this.error_messages.length > 0;
    }
    @computed get hasSuccess(){
        return this.error_messages.length === 0 && this.design.enfocus_status === EnfocusStatus.ENFOCUS_RESPONSE_SUCCESS;
    }

    constructor({design, ...rest}){
        makeAutoObservable(this);

        if(!design){
            throw new Error('Design is udefined');
        }
        this.design = design;


        this.fromJSON(rest);


    }


    @action fromJSON(json){

        this.data = json;
    }

    @action show(){
        this.isOpen = true;
    }
    @action hide(){
        this.isOpen = false;
    }
}