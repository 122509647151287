import React, { useRef, useEffect, useState } from "react";
// import "./styles.css";


export default function Collapse({ children, title, isOpen = false }) {
    const [open, setOpen] = useState(isOpen);
    const [closed, setClosed] = useState(!isOpen);
    const contentRef = useRef(null);

    useEffect(() => {
        setOpen(isOpen);
        setClosed(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        contentRef.current.style.height = open ? `${contentRef.current.scrollHeight}px` : "0px";
        setClosed(false);
        var timerId = setTimeout(() => {
            if (contentRef.current) {
                contentRef.current.style.height = open ? `auto` : '0px';
            }
            if (!open) {
                setClosed(true);
            }
        }, 300);
        return () => {
            clearTimeout(timerId);
        }
    }, [open]);

    const onClickHandler = () => {
        setOpen(!open);
    };

    const styles = {
        overflow: !open || closed ? 'hidden' : '',
        transition: 'height 0.3s ease',
    };
    if (open) {
        styles.padding = '1px';
    }

    return (
        <section className="Collapse">
            {title &&
                <div className="Collapse__trigger" onClick={onClickHandler}>{title}</div>
            }
            <div style={styles} className={'Collapse__content'} ref={contentRef}>{!closed && children}</div>
        </section>
    );
}
