

const SketchfabRender = props => {
    return <div className="SketchfabRender">
        <iframe
            src=""
            id="api-frame"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking=""
            execution-while-out-of-viewport=""
            execution-while-not-rendered=""
            web-share=""
            allowFullScreen=""
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
        />
        {props.children}


    </div>
}
SketchfabRender.displayName = 'SketchfabRender'

export default SketchfabRender